
import { defineComponent, PropType } from "vue";
// Types

// Libraries
import ClipboardJS from "clipboard";

// Utilities
import { downloadFile } from "@/components/DataSources/utilities";

// Components
import SigCloudStorageFileList from "@/components/DataSources/SigCloudStorageFileList.vue";
import LazyFigure from "@/components/DevelopmentPlatform/LazyFigure.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import OutputFailedDialog from "@/components/Common/OutputFailedDialog.vue";

import { ImageContent, ResultsPanelTab, TransformedTabContent } from "@/types/general";
import { useOutputsStore } from "@/stores/outputs";
import { defaultTabs } from "@/assets/defaultTabs";
import { transformTabContent } from "@/js/utilities";
import { useTasksStore } from "@/stores/tasks";

// Stores

// Global variables

// Local types
type ComponentData = {
	tab: number;
	newContent: boolean;
	tabBadges: boolean[];
	clipboard: null | any;
	showCopyNotification: boolean;
	autoPlotEnabled: undefined | boolean;
	showPlotDialog: boolean;
	dataForPlotDialog: undefined | ImageContent["body"];
	hoveredItem: null | ImageContent["body"];
	overlayTop: number;
	overlayLeft: number;
	plotInDialogLoading: boolean;
	prefetchObjectThreshold: number;
	chunkIsLoading: boolean;
	tabBadgeState: boolean[];
	showTaskFailedDialog: boolean;
	debounceTimer: ReturnType<typeof setTimeout>;
};

export default defineComponent({
	name: "Panel",
	components: { SigCloudStorageFileList, LazyFigure, TooltipButton, OutputFailedDialog },
	props: {
		resultText: {
			type: Array as PropType<ResultsPanelTab[]>,
			default: () => [...defaultTabs], // Use a function to return the default value
		},
		resultsTextAreaMinHeight: { type: String, default: "16vh" },
		resultsTextAreaMaxHeight: { type: String, default: "100%" },
		showCopyButton: { type: Boolean, default: false },
		showClearButton: { type: Boolean, default: false },
		taskID: { type: String, required: true },
		buildID: { type: String, required: true },
		resultsLoading: { type: Boolean, default: false },
	},
	data: (): ComponentData => ({
		tab: 0,
		newContent: false,
		tabBadges: [],
		clipboard: null,
		showCopyNotification: false,
		autoPlotEnabled: undefined,
		showPlotDialog: false,
		dataForPlotDialog: undefined,
		hoveredItem: null,
		overlayTop: 0,
		overlayLeft: 0,
		plotInDialogLoading: false,
		prefetchObjectThreshold: 15,
		chunkIsLoading: false,
		tabBadgeState: Array(defaultTabs.length).fill(false),
		showTaskFailedDialog: false,
		debounceTimer: setTimeout(() => {}, 0),
	}),
	watch: {
		taskID(newVal, oldVal) {
			if (newVal != oldVal) {
				this.tabBadgeState = Array(defaultTabs.length).fill(false);
			}
		},
		buildID(newVal, oldVal) {
			if (newVal != oldVal) {
				this.tabBadgeState = Array(defaultTabs.length).fill(false);
			}
		},
		tab(newVal, oldVal) {
			this.tabBadgeState[newVal] = false;
		},
		storeStdout: "updateActiveTab",
		storeStderr: "updateActiveTab",
		storeBuild: "updateActiveTab",
		storeStats: "updateActiveTab",
	},
	setup() {
		const outputStore = useOutputsStore();
		const tasksStore = useTasksStore();

		return { outputStore, tasksStore };
	},
	methods: {
		updateActiveTab() {
			const newTab = this.findFirstNonEmptyTab();
			clearTimeout(this.debounceTimer);
			this.debounceTimer = setTimeout(() => {
				if (this.tab !== newTab) {
					this.tab = newTab;
				}
			}, 500);
		},
		onHoverEnter(item, event) {
			this.hoveredItem = item;
			const screenHeight = window.innerHeight;
			const screenWidth = window.innerWidth;
			const isLowerHalf = event.clientY > screenHeight / 2;
			const isRightHalf = event.clientX > screenWidth / 2;

			if (isLowerHalf) {
				this.overlayTop = event.clientY - 300;
			} else {
				this.overlayTop = event.clientY + 30;
			}

			if (isRightHalf) {
				this.overlayLeft = event.clientX - 300;
			} else {
				this.overlayLeft = event.clientX + 30;
			}
		},
		onHoverLeave() {
			this.hoveredItem = null;
			this.overlayLeft = 0;
			this.overlayTop = 0;
		},

		requestClearTextArea() {
			/*
			 *  requestClearTextArea needs to be a method to have the 'this' context.
			 */
			this.$emit("panel-cleared");
		},
		copyOutputToClipboard(selectedTabIndex: number) {
			const textToCopy = document.getElementById(`results-sheet-id-${selectedTabIndex}`)?.innerText;
			if (textToCopy) {
				navigator.clipboard.writeText(textToCopy);
				this.showCopyNotification = true;
			}
		},
		copyToClipboard(selectedTabIndex: number) {
			// @ts-ignore FIXME:
			const textarea = this.$refs[`textarea-${selectedTabIndex}`].$el.querySelector("textarea");
			textarea.select();
			textarea.setSelectionRange(0, 99999);
			document.execCommand("copy");
			window.getSelection()?.removeAllRanges();
		},
		openPlotDialog(data: ImageContent["body"]) {
			this.dataForPlotDialog = data;
			this.showPlotDialog = true;
		},
		closePlotDialog() {
			this.showPlotDialog = false;
			this.dataForPlotDialog = undefined;
		},
		downloadFile(filePath: string) {
			downloadFile(filePath, "plot.png");
		},
		toggleAutoplot() {
			this.autoPlotEnabled = !this.autoPlotEnabled;
			localStorage.setItem("resultsPanel-autoplot", this.autoPlotEnabled.toString());
		},
		async fetchData() {
			if (!this.hasMoreChunks || this.chunkIsLoading) return;

			this.chunkIsLoading = true;
			try {
				await this.outputStore.fetchNextChunk(this.taskID);
			} catch (error) {
				this.showTaskFailedDialog = true;
				throw error;
			}

			this.chunkIsLoading = false;
		},
		handleScroll() {
			const container = this.$refs.resultsContainer as HTMLElement;

			if (!container) return;

			const threshold = container.scrollHeight * 0.7;
			const scrollPosition = container.scrollTop + container.clientHeight;
			if (scrollPosition >= threshold && !this.chunkIsLoading && !this.isLoading) {
				this.fetchData();
			}
		},
		async checkAndFetchMoreData() {
			await this.$nextTick();

			const container = this.$refs.resultsContainer;
			if (container instanceof HTMLElement) {
				if (container.scrollHeight > container.clientHeight || !this.hasMoreChunks) {
					return;
				}

				await this.fetchData();
				setTimeout(() => {
					this.checkAndFetchMoreData();
				}, 100);
			}
		},
		findFirstNonEmptyTab() {
			for (let i = 0; i < this.resultText.length; i++) {
				const code = this.resultText[i].code;
				if (!this.resultText[i].disabled) {
					if (
						(code === "Stdout" && this.storeStdout.length !== 0) ||
						(code === "Stderr" && this.storeStderr.length !== 0) ||
						(code === "Build" && this.storeBuild.length !== 0) ||
						(code === "Stats" && this.storeStats.length !== 0)
					) {
						return i;
					}
				}
			}
			return 0; // Default to first tab if all are empty
		},
	},
	computed: {
		storeStdout() {
			const taskOutputsStore = useOutputsStore();
			const taskID = this.taskID ?? "";
			const stringStdout = taskOutputsStore.getStringStdout(taskID);
			return transformTabContent(stringStdout);
		},
		storeStderr() {
			const taskOutputsStore = useOutputsStore();
			const taskID = this.taskID ?? "";
			const stringStdout = taskOutputsStore.getStringStderr(taskID);
			return transformTabContent(stringStdout);
		},
		storeBuild() {
			const outputsStore = useOutputsStore();
			const stringStdout = outputsStore.getStringBuild(this.buildID);
			return transformTabContent(stringStdout);
		},
		storeStats() {
			const taskOutputsStore = useOutputsStore();
			const taskID = this.taskID ?? "";
			const stringStdout = taskOutputsStore.getStringStats(taskID);
			return transformTabContent(stringStdout);
		},
		isLoading() {
			const outputsStore = useOutputsStore();
			const taskID = this.taskID ?? "";
			const buildID = this.buildID ?? "";
			const isBuildLoading = outputsStore.getBuildLoadingState(buildID);
			const isTaskLoading = outputsStore.getTaskLoadingState(taskID);
			// TODO: Check if this is playing well with the rest
			return isBuildLoading && isTaskLoading;
		},
		hasMoreChunks(): Boolean {
			const code = this.resultText[this.tab].code;
			if (code == "Stdout") {
				const store = useOutputsStore();
				const taskID = this.taskID ?? "";
				return store.getStdoutHasMoreChunks(taskID);
			}
			// We support only stdout chunks.
			return false;
		},
		transformedContents(): TransformedTabContent[] {
			const code = this.resultText[this.tab].code;
			if (code == "Stdout") {
				return this.storeStdout;
			}
			if (code == "Stderr") {
				return this.storeStderr;
			}
			if (code == "Build") {
				return this.storeBuild;
			}
			if (code == "Stats") {
				return this.storeStats;
			}
			return [];
		},
		tabBadge(): Boolean {
			const code = this.resultText[this.tab].code;
			if (code == "Stdout") {
				return this.storeStdout.length != 0;
			}
			if (code == "Stderr") {
				return this.storeStderr.length != 0;
			}
			if (code == "Build") {
				return this.storeBuild.length != 0;
			}
			if (code == "Stats") {
				return this.storeStats.length != 0;
			}
			return false;
		},
	},
	async mounted() {
		this.clipboard = new ClipboardJS(".copy-btn");
		this.$nextTick(() => {
			const container = this.$refs.resultsContainer;
			if (container instanceof HTMLElement) {
				container.addEventListener("scroll", this.handleScroll);
				this.fetchData();
			} else {
				console.error("Expected an HTMLElement but got:", container);
			}
		});
		this.autoPlotEnabled = (localStorage.getItem("resultsPanel-autoplot") ?? "false") === "true";

		localStorage.setItem("resultsPanel-autoplot", this.autoPlotEnabled.toString());
		try {
			const task = this.tasksStore.getTaskByID(this.taskID);
			await this.outputStore.loadBuildOutputs(task?.BuildID ?? "");
			await this.outputStore.loadTaskOutputs(this.$props.taskID ?? "");
		} catch (error) {
			this.showTaskFailedDialog = true;
			// No reason to continue
			return;
		}
		this.updateActiveTab();
		await this.checkAndFetchMoreData();
	},
	async beforeDestroy() {
		this.clipboard?.destroy();
		const container = this.$refs.resultsContainer as HTMLElement;
		container?.removeEventListener("scroll", this.handleScroll);
		await this.outputStore.cleanUpTask(this.$props.taskID ?? "");
	},
});
