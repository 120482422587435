export type ObjectValues<T> = T[keyof T];

// based on https://stackoverflow.com/questions/43159887/make-a-single-property-optional-in-typescript
export type MakeKeysOptional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export enum CORE_NAMES {
	C0_XS = "c0-xs",
	C0PRO_XS = "c0pro-xs",
	C0_XS_PLUS = "c0-xs+",
	C0PRO_XS_PLUS = "c0pro-xs+",
	X0_S = "x0-s",
	C0PRO_S = "c0pro-s",
	C0_S_PLUS = "c0-s+",
	C0PRO_S_PLUS = "c0pro-s+",
	C0_M = "c0-m",
	C0PRO_M = "c0pro-m",
	C0_M_PLUS = "c0-m+",
	C0PRO_M_PLUS = "c0pro-m+",
	C0_L = "c0-l",
	C0PRO_L = "c0pro-l",
	C0_L_PLUS = "c0-l+",
	C0PRO_L_PLUS = "c0pro-l+",
	C0_XL = "c0-xl",
	C0PRO_XL = "c0pro-xl",
	C0_XL_PLUS = "c0-xl+",
	C0PRO_XL_PLUS = "c0pro-xl+",
	C0_BYPASS = "c0-bypass",
	C0PRO_BYPASS = "c0pro-bypass",
	C0_REFERENCE = "c0-reference",
	C0PRO_REFERENCE = "c0pro-reference",
	C0PRO_JUPITER_S = "c0pro-jupiter-s",
}

export const CORE_NAMES_TO_DESCRIPTION: { [c in CORE_NAMES]: string } = {
	[CORE_NAMES.C0_XS]:
		"Use this core type when you want to evaluate the simplest form of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0PRO_XS]:
		"Use this core type when you want to evaluate the high-performance variant of the simplest form of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0_XS_PLUS]:
		"Use this core type when you want to evaluate the simplest form of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0PRO_XS_PLUS]:
		"Use this core type when you want to evaluate the high-performance variant of the simplest form of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.X0_S]:
		"Use this core type when you want to evaluate something slightly better than the simplest form of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0PRO_S]:
		"Use this core type when you want to evaluate the high-performance variant of something slightly better than the simplest form of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0_S_PLUS]:
		"Use this core type when you want to evaluate something slightly better than the simplest form of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0PRO_S_PLUS]:
		"Use this core type when you want to evaluate the high-performance variant of something slightly better than the simplest form of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0_M]:
		"Use this core type when you want to evaluate a general-purpose accuracy form of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0PRO_M]:
		"Use this core type when you want to evaluate the high-performance variant of a general-purpose accuracy form of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0_M_PLUS]:
		"Use this core type when you want to evaluate a general-purpose accuracy form of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0PRO_M_PLUS]:
		"Use this core type when you want to evaluate the high-performance variant of a general-purpose accuracy form of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0_L]:
		"Use this core type when you want to evaluate a high-accuracy and large-memory configuration of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0PRO_L]:
		"Use this core type when you want to evaluate the high-performance variant of a high-accuracy and large-memory configuration of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0_L_PLUS]:
		"Use this core type when you want to evaluate a high-accuracy and large-memory configuration of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0PRO_L_PLUS]:
		"Use this core type when you want to evaluate the high-performance variant of a high-accuracy and large-memory configuration of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0_XL]:
		"Use this core type when you want to evaluate the highest-accuracy and largest-memory configuration of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0PRO_XL]:
		"Use this core type when you want to evaluate the high-performance variant of the highest-accuracy and largest-memory configuration of Signaloid's uncertainty-tracking core types.",
	[CORE_NAMES.C0_XL_PLUS]:
		"Use this core type when you want to evaluate the highest-accuracy and largest-memory configuration of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0PRO_XL_PLUS]:
		"Use this core type when you want to evaluate the high-performance variant of the highest-accuracy and largest-memory configuration of Signaloid's uncertainty-tracking core types, with an added ability to track basic autocorrelations.",
	[CORE_NAMES.C0_BYPASS]:
		"Use this core type when you don't need any uncertainty tracking and simply want to test the basic functionality of your code.",
	[CORE_NAMES.C0PRO_BYPASS]:
		"High-performance variant of a core type that you can use when you don't need any uncertainty tracking and simply want to test the basic functionality of your code.",
	[CORE_NAMES.C0_REFERENCE]:
		"Use this core type when you want uncertainty tracking that is guaranteed to be on-par with performing a brute-force Monte Carlo. This core type does not provide the significant speedups of the other core types but still gives you uncertainty tracking without needing to construct your own Monte Carlo evaluations (random number generation, evaluation, post-processing) around your baseline code.",
	[CORE_NAMES.C0PRO_REFERENCE]:
		" High-performance variant of the core type that you can use when you want uncertainty tracking that is guaranteed to be on-par with performing a brute-force Monte Carlo. This core type does not provide the significant speedups of the other core types but still gives you uncertainty tracking without needing to construct your own Monte Carlo evaluations (random number generation, evaluation, post-processing) around your baseline code.",
	[CORE_NAMES.C0PRO_JUPITER_S]:
		"Use this core type when you want to evaluate the advanced form of Signaloid's uncertainty-tracking core types, with the added ability to track correlations.",
};
