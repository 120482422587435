// Libraries
import { monitoringCaptureError } from "@/plugins/monitoring";
import { TaskStatus } from "@/types/api/tasks";
import axios, { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
// Types
import {
	GetTask200Response,
	GetTaskOutputStreamsResponse,
	GetTaskOutputStreamURLs200Response,
	GetTaskOutputStreamURLsResponse,
	ListPartialTasks200Response,
	ListTasks200Response,
} from "../signaloidClient.types";

/**
 * Get the list of tasks metadata in the account.
 * This list is not affected by tier restrictions.
 *
 * @returns {Promise<AxiosResponse<ListPartialTasks200Response>>} A promise that resolves to a "200 OK" along with
 * a list of tasks metadata objects with the task data from the DB.
 */
export function getUserTasksMetadata(
	from?: string,
	to?: string,
	startKey?: string
): Promise<AxiosResponse<ListPartialTasks200Response>> {
	const params = new URLSearchParams();

	if (from) {
		params.append("from", from);
	}
	if (to) {
		params.append("to", to);
	}
	if (startKey) {
		params.append("startKey", startKey);
	}
	params.append("noexpand", "true");

	return signaloidApiClient.get(`/tasks`, { params });
}

/**
 * Get the list of tasks in the account.
 *
 * @returns {Promise<AxiosResponse<ListTasks200Response>>} A promise that resolves to a "200 OK" along with
 * a list of task objects with the task data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Tasks/operation/GetTasks
 */
export function getUserTasks(
	startKey?: string,
	taskStatus?: TaskStatus[]
): Promise<AxiosResponse<ListTasks200Response>> {
	const params = new URLSearchParams();

	if (taskStatus) {
		taskStatus.forEach((status) => {
			params.append("status", status);
		});
	}

	if (startKey) {
		params.append("startKey", startKey);
	}

	return signaloidApiClient.get(`/tasks`, { params: params });
}

/**
 * Get a task resource by TaskID.
 *
 * @param {string} TaskID The identifier of the task.
 * @returns {Promise<AxiosResponse<GetTask200Response>>} A promise that resolves to a "200 OK" along with a
 * task object with the task data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Tasks/operation/GetTask
 */
export function getTaskByID(TaskID: string): Promise<AxiosResponse<GetTask200Response>> {
	return signaloidApiClient.get(`/tasks/${TaskID}`);
}

/**
 * Cancel a task resource by TaskID.
 *
 * @param {string} TaskID The identifier of the task.
 * @returns {Promise<AxiosResponse<void>>} A promise that resolves to a "202 Accepted" response if task
 * cancel request is successfully submitted.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Tasks/operation/CancelTask
 */
export function cancelTaskByID(TaskID: string): Promise<AxiosResponse<void>> {
	return signaloidApiClient.post(`/tasks/${TaskID}/cancel`);
}

/**
 * Remove task metadata by TaskID.
 *
 * @param {string} TaskID The identifier of the task.
 * @returns {Promise<AxiosResponse<void>>} A promise that resolves to a "204" response if task
 * is successfully removed.
 */
export function DeleteTaskMetadataById(TaskID: string): Promise<AxiosResponse<void>> {
	return signaloidApiClient.delete(`/tasks/${TaskID}`);
}

function getTaskOutputStreamURLs(TaskID: string): Promise<AxiosResponse<GetTaskOutputStreamURLs200Response>> {
	return signaloidApiClient.get(`/tasks/${TaskID}/outputs`);
}

export async function getTaskOutputURLs(TaskID: string): Promise<AxiosResponse<GetTaskOutputStreamURLsResponse>> {
	const taskOutputs = await signaloidApiClient.get(`/tasks/${TaskID}/outputs`);
	return taskOutputs;
}

export async function getTaskOutputs(TaskID: string): Promise<GetTaskOutputStreamsResponse> {
	const outputs: { [key: string]: string } = {};

	try {
		const response = await getTaskOutputStreamURLs(TaskID);
		const outputStreamURLs = response.data;

		const fetchPromises = Object.entries(outputStreamURLs).map(async ([key, streamURL]) => {
			try {
				const streamResponse = await axios.get(streamURL, { responseType: "json" }); // Assume JSON response
				outputs[key] =
					typeof streamResponse.data === "object"
						? JSON.stringify(streamResponse.data, null, 2)
						: String(streamResponse.data);
			} catch (error) {
				monitoringCaptureError(error, `Fetch task output stream: ${key}`);
			}
		});

		await Promise.all(fetchPromises);
	} catch (error) {
		monitoringCaptureError(error, "Fetch task output streams");
	}

	return outputs;
}

/**
 * Dynamically request the plotting of a Value using the TaskID and the ValueID.
 *
 * @param {string} taskID The ID of the Task that generated the Value.
 * @param {string} valueID The ID of the Value (given in the Task results).
 * @returns {Promise<AxiosResponse<{[key:string]:any}>>} When successful, the response contains
 * fields`plotID` and `presignedURL`. On exception, it contains a `message` field.
 * It may contain additional fields (both for success and failure).
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Plots/operation/PlotValue
 */
export function plotValue(taskID: string, valueID: string): Promise<AxiosResponse<{ [key: string]: any }>> {
	return signaloidApiClient.post(
		`/tasks/${taskID}/values/${valueID}/plot`,
		{},
		{
			timeout: 30000 /* Override and set to 30s the signaloidApiClient default timeout of 10s for the plotting. */,
		}
	);
}

/**
 * Dynamically request the plotting of a Ux string.
 *
 * @param {string} uxString The ID of the Task that generated the Value.
 * @returns {Promise<AxiosResponse<{[key:string]:any}>>} When successful, the response contains
 * fields`plotID` and `presignedURL`. On exception, it contains a `message` field.
 * It may contain additional fields (both for success and failure).
 */
export function plotUxValue(uxString: string): Promise<AxiosResponse<{ [key: string]: any }>> {
	return signaloidApiClient.post("/plot", { payload: uxString });
}
