// Libraries
import { monitoringCaptureError } from "@/plugins/monitoring";
import axios, { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
// Types
import {
	CreateRepositoryBuildRequest,
	CreateSourceCodeBuildRequest,
	GetBuild200Response,
	GetBuildOutputStreamsResponse,
	GetBuildOutputStreamURLsResponse,
	GetBuildVariables200Response,
	ListBuilds200Response,
	RepositoryBuildResponse,
	RunBuildRequest,
	SourceCodeBuildResponse,
	TaskRunResponse,
} from "../signaloidClient.types";
import { BuildStatus } from "@/types/api/builds";

/**
 * Runs a Build.
 *
 * @returns {Promise<AxiosResponse<TaskRunResponse>>} A promise that resolves to a "202 Accepted" along with
 * TaskID if the request is successful.
 */
export function startBuildTask(
	buildID: string,
	request: RunBuildRequest,
	org?: string
): Promise<AxiosResponse<TaskRunResponse>> {
	if (org) {
		return signaloidApiClient.post(`/builds/${buildID}/tasks?org=${org}`, request);
	}
	return signaloidApiClient.post(`/builds/${buildID}/tasks`, request);
}

/**
 * Starts a source code Build.
 *
 * @returns {Promise<AxiosResponse<SourceCodeBuildResponse>>} A promise that resolves to a "202 Accepted" along with
 * BuildID if the request is successful.
 */
export function startSourceCodeBuild(
	request: CreateSourceCodeBuildRequest,
	org?: string
): Promise<AxiosResponse<SourceCodeBuildResponse>> {
	if (org) {
		return signaloidApiClient.post(`/sourcecode/builds?org=${org}`, request);
	}
	return signaloidApiClient.post(`/sourcecode/builds`, request);
}
/**
 * Starts a source code discover variables Build.
 *
 * @returns {Promise<AxiosResponse<SourceCodeBuildResponse>>} A promise that resolves to a "202 Accepted" along with
 * BuildID if the request is successful.
 */
export function startSourceCodeDiscoverVariablesBuild(
	request: CreateSourceCodeBuildRequest,
	org?: string
): Promise<AxiosResponse<SourceCodeBuildResponse>> {
	if (org) {
		return signaloidApiClient.post(`/sourcecode/builds?org=${org}&DiscoverVariables=true`, request);
	}
	return signaloidApiClient.post(`/sourcecode/builds?DiscoverVariables=true`, request);
}

/**
 * Starts a repository Build.
 *
 * @returns {Promise<AxiosResponse<RepositoryBuildResponse>>} A promise that resolves to a "202 Accepted" along with
 * BuildID if the request is successful.
 */
export function startRepositoryBuild(
	repositoryID: string,
	request: CreateRepositoryBuildRequest,
	org?: string
): Promise<AxiosResponse<RepositoryBuildResponse>> {
	if (org) {
		return signaloidApiClient.post(`/repositories/${repositoryID}/builds?org=${org}`, request);
	}
	return signaloidApiClient.post(`/repositories/${repositoryID}/builds`, request);
}
/**
 * Starts a repository Build.
 *
 * @returns {Promise<AxiosResponse<RepositoryBuildResponse>>} A promise that resolves to a "202 Accepted" along with
 * BuildID if the request is successful.
 */
export function startRepositoryDiscoverVariablesBuild(
	repositoryID: string,
	request: CreateRepositoryBuildRequest,
	org?: string
): Promise<AxiosResponse<RepositoryBuildResponse>> {
	if (org) {
		return signaloidApiClient.post(`/repositories/${repositoryID}/builds?org=${org}&DiscoverVariables=true`, request);
	}
	return signaloidApiClient.post(`/repositories/${repositoryID}/builds?DiscoverVariables=true`, request);
}
/**
 * Get the list of builds in the account.
 *
 * @returns {Promise<AxiosResponse<ListBuilds200Response>>} A promise that resolves to a "200 OK" along with
 * a list of builds objects with the task data from the DB.
 */
export function getUserBuilds(
	startKey?: string,
	buildStatus?: BuildStatus[]
): Promise<AxiosResponse<ListBuilds200Response>> {
	const params = new URLSearchParams();

	if (buildStatus) {
		buildStatus.forEach((status) => {
			params.append("status", status);
		});
	}

	if (startKey) {
		params.append("startKey", startKey);
	}

	return signaloidApiClient.get(`/builds`, { params: params });
}

/**
 * Get a build resource by BuildID.
 *
 * @param {string} BuildID The identifier of the build.
 * @returns {Promise<AxiosResponse<GetBuild200Response>>} A promise that resolves to a "200 OK" along with a
 * build object with the build data from the DB.
 */
export function getBuildByID(BuildID: string): Promise<AxiosResponse<GetBuild200Response>> {
	return signaloidApiClient.get(`/builds/${BuildID}`);
}

/**
 * Cancel a build resource by BuildID.
 *
 * @param {string} BuildID The identifier of the build.
 * @returns {Promise<AxiosResponse<void>>} A promise that resolves to a "202 Accepted" response if build
 * cancel request is successfully submitted.
 */
export function cancelBuildByID(BuildID: string): Promise<AxiosResponse<void>> {
	return signaloidApiClient.post(`/builds/${BuildID}/cancel`);
}

/**
 * List discovered Tasks variables. Returns the list of variables that the
 * Signaloid Cloud Compute Engine has discovered in the Build code.
 * Currently only supported in Build with only one computational Node.
 *
 * @param {string} BuildID The identifier of the build.
 * @returns {Promise<AxiosResponse<GetBuildVariables200Response>>} A promise that resolves to a "200 OK" along with a list of
 * variable objects with the variable data from the DB.
 */
export function getBuildVariables(BuildID: string): Promise<AxiosResponse<GetBuildVariables200Response>> {
	return signaloidApiClient.get(`/builds/${BuildID}/variables`);
}

export function getBuildOutputStreamURLs(BuildID: string): Promise<AxiosResponse<GetBuildOutputStreamURLsResponse>> {
	return signaloidApiClient.get(`/builds/${BuildID}/outputs`);
}

async function getBuildOutputURLs(BuildID: string): Promise<AxiosResponse<GetBuildOutputStreamURLsResponse>> {
	const taskOutputs = await signaloidApiClient.get(`/builds/${BuildID}/outputs`);
	return taskOutputs;
}

export async function getBuildOutputs(BuildID: string): Promise<GetBuildOutputStreamsResponse> {
	const outputs: { [key: string]: string } = {};

	try {
		const response = await getBuildOutputURLs(BuildID);
		const outputStreamURLs = response.data;

		const fetchPromises = Object.entries(outputStreamURLs).map(async ([key, streamURL]) => {
			try {
				const streamResponse = await axios.get(streamURL, { responseType: "json" }); // Assume JSON response
				outputs[key] =
					typeof streamResponse.data === "object"
						? JSON.stringify(streamResponse.data, null, 2)
						: String(streamResponse.data);
			} catch (error) {
				monitoringCaptureError(error, `Fetch task output stream: ${key}`);
			}
		});

		await Promise.all(fetchPromises);
	} catch (error) {
		monitoringCaptureError(error, "Fetch task output streams");
	}

	return outputs;
}
