
import { defineComponent } from "vue";

// Libraries
import { Auth } from "aws-amplify";

// Utilities
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import TopNav from "@/components/TopNav.vue";
import TierUpgradeHintDialog from "@/components/Common/TierUpgradeHintDialog.vue";
import HelpButton from "@/components/Common/HelpButton.vue";

// Stores
import { mapState } from "pinia";
import { useDataSourcesStore } from "@/stores/dataSources";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useCoresStore } from "@/stores/cores";
import { useEditorStore } from "@/stores/editor";
import { useRepositoriesStore } from "@/stores/repositories";
import { useGithubStore } from "@/stores/github";
import { useRootStore } from "@/stores/root";
import { usePipelinesStore } from "@/stores/pipelines";
import { identifyAnalyticsUser } from "./plugins/identify";
import { TierLimitBusEvent, TierLimitEventTypeE } from "./eventBus/tierLimitEventBus";
import { UserLimits } from "./js/tierconfig";
import userflow from "userflow.js";
import { useOutputsStore } from "./stores/outputs";
import { useBuildsStore } from "./stores/builds";

type ComponentData = {
	drawer: boolean;
	drawerItems: DrawerItem[];
	auth: {
		user: undefined | string;
		state: undefined | string;
		data: undefined | Awaited<ReturnType<typeof Auth.currentAuthenticatedUser>>;
	};
	tierLimitEventBusSub: undefined | Function;
	exceededTierLimitsForHintDialog: UserLimits[];
	showLimitHintDialog: boolean;
	fatalErrorInitialising: boolean;
};

type DrawerItem = {
	text: string;
	to: string;
	icon: string;
	disabled: boolean;
};

export default defineComponent({
	name: "App",
	components: { TopNav, TierUpgradeHintDialog, HelpButton },
	data: (): ComponentData => ({
		drawer: true,
		drawerItems: [
			{ text: "Home", to: "/home", icon: "mdi-home-variant-outline", disabled: false },
			{ text: "Code Editor", to: "/launch", icon: "mdi-xml", disabled: false },
			{ text: "Cores", to: "/cores", icon: "mdi-memory", disabled: false },
			{ text: "Data and Sensors", to: "/datasources", icon: "mdi-database-outline", disabled: false },
			{ text: "Repositories", to: "/repositories", icon: "mdi-github", disabled: false },
			// Disabled till api migration for pipelines is done
			//  { text: "Pipelines", to: "/pipelines", icon: "mdi-file-tree", disabled: false },
			{ text: "Apps", to: "/apps", icon: "mdi-apps", disabled: false },
			{ text: "Task Manager", to: "/tasks", icon: "mdi-format-list-group", disabled: false },
			{ text: "Usage", to: "/usage", icon: "mdi-chart-arc", disabled: false },
			{ text: "Billing", to: "/billing", icon: "mdi-credit-card-outline", disabled: false },
			{ text: "Settings", to: "/settings", icon: "mdi-cog-outline", disabled: false },
		],
		auth: {
			user: undefined,
			state: undefined,
			data: undefined,
		},
		tierLimitEventBusSub: undefined,
		exceededTierLimitsForHintDialog: [],
		showLimitHintDialog: false,
		fatalErrorInitialising: false,
	}),
	computed: {
		drawerActiveItem(): number | null {
			const routeDrawerIndex = this.drawerItems.findIndex((element) => this.$route.path.startsWith(element.to));
			return routeDrawerIndex != -1 ? routeDrawerIndex : null;
		},
		...mapState(useRootStore, { platformLogo: "platformLogo" }),
		versionHash(): string {
			return process.env.VUE_APP_COMMIT_HASH ? process.env.VUE_APP_COMMIT_HASH.slice(0, 7) : "-";
		},
	},
	methods: {
		logStartupInfo() {
			const version = process.env.VUE_APP_VERSION;
			queueMicrotask(
				console.log.bind(
					console,
					`Signaloid Cloud Developer Platform (v${version}-${this.versionHash})\nCopyright 2024 Signaloid`
				)
			);
		},
		async awsSignOut() {
			try {
				await Auth.signOut();
				util.clearLocalState();
				const taskOutputsStore = useOutputsStore();
				await taskOutputsStore.purgeCache();
				window.location.href = "/login";
			} catch (error) {
				monitoringCaptureError(error, "User sign out");
				util.clearLocalState();
				window.location.href = "/login";
			}
		},
		async tierLimitEventBusCallback(event: TierLimitBusEvent) {
			// This function will handle global tier limit events and show the dialog if needed
			if (event.type === TierLimitEventTypeE.UsageChanged) {
				// this might be expensive, but necessary until we have web socket setup
				this.userStore.fetchCurrentUserObjectFromDB();
			} else if (event.type === TierLimitEventTypeE.LimitExceeded) {
				// check if the dialog is already visible
				if (this.showLimitHintDialog !== true) {
					this.exceededTierLimitsForHintDialog = event.affectedLimits;
					this.showLimitHintDialog = true;
				}

				event.affectedLimits.map((affectedLimit) => {
					// @ts-ignore
					this.$posthog?.capture("user_hit_tier_limit", {
						affectedLimits: affectedLimit,
					});
				});
			}
		},
		subscribeToTierLimitEventBus() {
			this.tierLimitEventBusSub = this.rootStore.tierLimitEventBus.on(this.tierLimitEventBusCallback);
		},
		initialiseUserflow() {
			// set userflow resource centre to be hidden initially
			userflow.setResourceCenterLauncherHidden(!this.rootStore.showHelpButton);

			// Monitor resource centre visibility and hide default docs icon
			userflow.on("resourceCenterChanged", () => {
				const resourceCenterState = userflow.getResourceCenterState();
				this.rootStore.userflowLoaded = resourceCenterState === null ? false : true;
			});

			//wait for the platform to finish loading and enable the resource centre
			util.waitFor(() => this.rootStore.platformGlobalState === "Ready").then(() => {
				this.rootStore.showHelpButton = true;
				userflow.setResourceCenterLauncherHidden(!this.rootStore.showHelpButton);
			});
		},
	},
	setup() {
		// Note: gets called after beforeCreate in vue2
		const dataSourcesStore = useDataSourcesStore();
		const userStore = useUserStore();
		const coresStore = useCoresStore();
		const editorStore = useEditorStore();
		const repositoriesStore = useRepositoriesStore();
		const githubStore = useGithubStore();
		const rootStore = useRootStore();
		const tasksStore = useTasksStore();
		const buildStore = useBuildsStore();

		return {
			dataSourcesStore,
			userStore,
			coresStore,
			editorStore,
			repositoriesStore,
			githubStore,
			rootStore,
			tasksStore,
			buildStore,
			util,
		};
	},
	beforeCreate() {
		//
		// console.log("And there was light");
		/*
		 *	WARNING: Do not change the order of the three following function calls below!
		 */
		// const rootStore = useRootStore();
		// rootStore.setCodeLanguagesList(require("@/assets/_codeLanguagesList.json"));
		// // set auth head if gh authenticated
		// const githubStore = useGithubStore();
		// githubStore.setupClientAndGetUser();
		const pipelinesStore = usePipelinesStore();
		pipelinesStore.initialisePipelinesFromLocalStorage();
	},

	async created() {
		this.logStartupInfo();
		this.initialiseUserflow();
		this.rootStore.setupListenerForOnlineStatus();

		try {
			// Get defaults of the authenticated user from AWS and DB
			// Note: fetching this details from the user store via this.userStore.fetchCurrentUserFromAWS();
			// put kea-web into a login loop when you are not logged in ????
			// await Auth.currentAuthenticatedUser();
			await this.userStore.fetchCurrentUserFromAWS();
		} catch (error) {
			// User is not authenticated. Clear local state and redirect to login
			util.clearLocalState();
			window.location.href = "/login";
			return;
		}

		try {
			await this.userStore.fetchCurrentUserSessionFromAWS();
		} catch (error) {
			console.log("fetchCurrentUserSessionFromAWS exception:");
			console.log(error);
			await this.awsSignOut();
			window.location.href = "/login";
			return;
			// unreachable
		}

		try {
			/*
			 *	identifyAnalyticsUser can except but it makes the same call as the two try-catch blocks above it. If
			 *	these succeeded then identifyAnalyticsUser has great probability to succeed. A workaround would be to
			 *	pass information along and not re-call the underlying exception-possible functions.
			 */
			await identifyAnalyticsUser();
		} catch (error) {
			/*
			 *	We shouldn't stop the App from running if this failed. But we can set-up a retry.
			 */
			setTimeout(identifyAnalyticsUser, 500 /* ms */);
		}

		try {
			// If any of these requests fail, we cannot continue
			// TODO: Why not?
			await this.userStore.fetchCurrentUserSubscriptions();
		} catch (error) {
			this.fatalErrorInitialising = true;
			console.log("fetchCurrentUserSubscriptions exception:");
			console.log(error);
		}

		util.waitFor(() => this.rootStore.platformGlobalState === "InitialisingUserAuth").then(() => {
			this.userStore.fetchCurrentUserObjectFromDB();
		});

		util.waitFor(() => this.rootStore.platformGlobalState === "Ready").then(() => {
			/*
			 *	Initialise user customisations
			 */
			this.userStore.fetchCurrentUserCustomizationsFromDB().then(() => {
				this.rootStore.updatePlatformLogo();
				this.rootStore.updatePlatformSpotlightSlides();
				this.rootStore.updatePlatformApplicationHighlights();
				this.rootStore.updateComputeEngineHost();
			});

			// Initialise the Editor
			this.editorStore.initialiseEditorStateFromRemote();

			// Initialise cores, data sources, an tasks
			this.githubStore.setupClientAndGetUser();

			// * Initialise cores, data sources, an tasks
			this.coresStore.initialiseExecutionCoresFromRemote();
			this.dataSourcesStore.initialiseDataSourcesFromRemote();
			this.tasksStore.initialiseTaskHistory();
			this.buildStore.initialiseBuildHistory();

			this.subscribeToTierLimitEventBus();
		});
	},
	beforeDestroy() {
		// return onAuthUIStateChange;
		if (this.tierLimitEventBusSub !== undefined) {
			this.tierLimitEventBusSub();
		}
	},
});
