
import { defineComponent, PropType, ref } from "vue";

// Types
import { Endpoints } from "@octokit/types";
import {
	BuildBusEvent,
	FileTree,
	RemoteAccessState,
	ResultsPanelTab,
	SignaloidConfigFile,
	SignaloidConfigFileMinimumCoreOptions,
	TaskBusEvent,
} from "@/types/general";
import { isTaskActive, isTaskTerminated, isTaskFail, Task, TaskStatusE } from "@/types/api/tasks";
import { DataSource, isLocalMountConfig, LocalMountConfig } from "@/types/api/dataSources";
import { Core } from "@/types/api/cores";
import { Repository } from "@/types/api/repositories";
import { TraceVariable, TraceVariableWithTraceType } from "@/types/api/nodes";
import { UserLimitsE } from "@/js/tierconfig";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { shortenID } from "@/js/utilities";

// Libraries
import moment from "moment";
import YAML, { YAMLError, YAMLParseError } from "yaml";
import axios from "axios";
import * as Sentry from "@sentry/vue";

// Utilities
import * as githubClient from "@/js/githubClient";
import * as signaloidClient from "@/js/signaloidClient";
import * as dsUtil from "@/components/DataSources/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";
import { base64Decode } from "@/js/utilities";

// Components
import BuildBar from "@/components/Common/BuildBar.vue";
import RunArgumentsDialog from "@/components/RunArgumentsDialog.vue";
import SelectCoreDialog from "@/components/SelectCoreDialog.vue";
import MountDataSourcesDialog from "@/components/MountDataSourcesDialog.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";
import LoadableChip from "@/components/Common/LoadableChip.vue";
import RepositoryBuildDirectoryDialog from "./RepositoryBuildDirectoryDialog.vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
import Editor from "@/components/Editor.vue";

// Stores
import { mapState } from "pinia";
import { useTasksStore } from "@/stores/tasks";
import { useCoresStore } from "@/stores/cores";
import { useRepositoriesStore } from "@/stores/repositories";
import { useRootStore } from "@/stores/root";
import { useGithubStore } from "@/stores/github";
import { useDataSourcesStore } from "@/stores/dataSources";
import { useUserStore } from "@/stores/user";
import { generateGithubResponseErrorMessage } from "@/js/githubUtilities";
import { useOutputsStore } from "@/stores/outputs";
import { useEditorStore } from "@/stores/editor";
import { Build, BuildStatusE, canFetchVariables, isBuildFail, isBuildTerminated } from "@/types/api/builds";
import { useBuildsStore } from "@/stores/builds";

// Local Types
type ComponentData = {
	repoDetailsFromRepoHost: null | Endpoints["GET /repos/{owner}/{repo}"]["response"]["data"];
	// repositoryFromDB: Repository;
	activeTask: undefined | Partial<Task>;
	activeBuild: undefined | Partial<Build>;
	showBuildBar: boolean;
	highlightedWorkingDirectory: FileTree[];
	selectedBuildDirectory: string;
	openTrees: any[];
	directoryTreeViewItems: any[];
	repositoryBranchesResponse: undefined | Endpoints["GET /repos/{owner}/{repo}/branches"]["response"]["data"];
	showDirectoryDialog: boolean;
	showRunArgumentsDialog: boolean;
	showSelectCoreDialog: boolean;
	showMountDataSourcesDialog: boolean;
	repoAccessState: RemoteAccessState;
	coreUpdateStatus: RemoteAccessState;
	branchUpdateStatus: RemoteAccessState;
	repoDisconnectStatus: RemoteAccessState;
	runArgumentsUpdateStatus: RemoteAccessState;
	buildDirectoryUpdateStatus: RemoteAccessState;
	dataSourceUpdateStatus: RemoteAccessState;
	taskAccessState: RemoteAccessState;
	buildAccessState: RemoteAccessState;
	taskResultsFetchStatus: RemoteAccessState;
	lastTaskResults: undefined | ResultsPanelTab[];
	taskEventBusSub: undefined | Function;
	buildEventBusSub: undefined | Function;
	repoConfigFilePath: undefined | null | string;
	repoConfigFileData: undefined | null | SignaloidConfigFile;
	repoConfigFileError: undefined | YAMLError;
	repoConfigFileWarnings: Array<string>;
	lastBuildForVariableDiscovery: boolean;
};

// Global variables
const defaultWorkingDirectory: FileTree = { name: "./", path: "", type: "dir", children: [] };

type ChipStyle = {
	color: string;
	outlined: boolean;
	textColor: string;
};

export default defineComponent({
	name: "ConnectedRepositoryCard",
	components: {
		RunArgumentsDialog,
		SelectCoreDialog,
		MountDataSourcesDialog,
		RepositoryBuildDirectoryDialog,
		BuildBar,
		TooltipButton,
		LimitableActionButton,
		LoadableChip,
		CopyToClipboardButton,
	},
	props: {
		repositoryID: { type: String, required: true },
		repository: { type: Object as PropType<Repository>, required: true },
		initialBuildBarStatus: { type: Boolean, default: false },
		variableTraceList: {
			type: Array as PropType<TraceVariable[]>,
			default: () => [],
		},
		uiRepositoryDetailMode: { type: Boolean, default: false },
	},
	emits: {
		"clear-results": () => true,
		"repository-disconnected": (data: { repositoryID: string }) => true,
		"open-execution-log": (data: { taskID: string; buildID: string }) => true,
		"repository-variables-updated": (data: TraceVariableWithTraceType[]) => true,
	},
	data: (): ComponentData => ({
		repoDetailsFromRepoHost: null,
		// repositoryFromDB: {},	// This is now part of setup() so we don't have to check nullish-ness
		activeTask: undefined,
		activeBuild: undefined,
		showBuildBar: false,
		highlightedWorkingDirectory: [defaultWorkingDirectory],
		selectedBuildDirectory: defaultWorkingDirectory.path,
		openTrees: [],
		repositoryBranchesResponse: undefined,
		directoryTreeViewItems: [],
		showDirectoryDialog: false,
		showRunArgumentsDialog: false,
		showSelectCoreDialog: false,
		showMountDataSourcesDialog: false,
		lastTaskResults: undefined,
		taskEventBusSub: undefined,
		buildEventBusSub: undefined,
		repoAccessState: { loading: false, error: false, message: "" },
		coreUpdateStatus: { loading: false, error: false, message: "" },
		branchUpdateStatus: { loading: false, error: false, message: "" },
		buildDirectoryUpdateStatus: { loading: false, error: false, message: "" },
		runArgumentsUpdateStatus: { loading: false, error: false, message: "" },
		dataSourceUpdateStatus: { loading: false, error: false, message: "" },
		repoDisconnectStatus: { loading: false, error: false, message: "" },
		taskAccessState: { loading: false, error: false, message: "" },
		buildAccessState: { loading: false, error: false, message: "" },
		taskResultsFetchStatus: { loading: false, error: false, message: "" },
		repoConfigFileData: undefined,
		repoConfigFilePath: undefined,
		repoConfigFileError: undefined,
		repoConfigFileWarnings: [],
		lastBuildForVariableDiscovery: false,
	}),
	setup() {
		const rootStore = useRootStore();
		const editorStore = useEditorStore();
		const editor = ref<InstanceType<typeof Editor>>();
		const tasksStore = useTasksStore();
		const buildsStore = useBuildsStore();
		const coresStore = useCoresStore();
		const userStore = useUserStore();
		const repositoriesStore = useRepositoriesStore();
		const githubStore = useGithubStore();
		const outputsStore = useOutputsStore();
		return {
			rootStore,
			tasksStore,
			buildsStore,
			coresStore,
			userStore,
			repositoriesStore,
			githubStore,
			isTaskActive,
			UserLimitsE,
			shortenID,
			outputsStore,
			editorStore,
			editor,
		};
	},
	methods: {
		/*
		 * Utilities
		 */
		formatDate(dateString) {
			return moment(dateString).format("DD MMM YYYY");
		},

		/*
		 * Getters
		 */
		async getRepoDataFromRemote() {
			if (this.remoteURLHost !== "GitHub") {
				throw new Error("non-GitHub repositories are not supported");
			}

			try {
				const response = await githubClient.getRepoData(this.repositoryFullName);
				this.repoDetailsFromRepoHost = response.data;

				await this.getRepoBranches();

				await this.getConfigFile();
			} catch (error) {
				if (axios.isAxiosError(error)) {
					this.repoAccessState.message = generateGithubResponseErrorMessage(
						error,
						this.githubStore.githubLoggedIn
					);
				} else {
					// Non-axios error
					this.repoAccessState.message =
						"The request to get repository information could not be created due to an unexpected error." +
						" If this error persists, please contact support at developer-support@signaloid.com.";
					// This was a non-axios error, i.e., not a network error, meaning that it probably is a code
					// error. We set the severity level to fatal.
				}
				this.repoAccessState.error = true;

				monitoringCaptureError(error, "getRepoDataFromRemote", {
					severity: "fatal",
					extras: {
						userMessage: this.repoAccessState.message,
					},
				});
			} finally {
				this.repoAccessState.loading = false;
			}
		},
		async getRepoBranches() {
			if (this.remoteURLHost !== "GitHub") {
				throw new Error("non-GitHub repositories are not supported");
			}
			this.branchUpdateStatus.loading = true;
			this.branchUpdateStatus.error = false;
			try {
				this.repositoryBranchesResponse = await githubClient.getAllRepoBranches(this.repositoryFullName);
			} catch (error) {
				monitoringCaptureError(error, "Fetch repository branches");
				if (axios.isAxiosError(error)) {
					this.branchUpdateStatus.message = "Could not get repository branches. ";

					// If its a github error, generate the error message
					if (error.config?.baseURL?.includes("api.github")) {
						this.branchUpdateStatus.message += generateGithubResponseErrorMessage(
							error,
							this.githubStore.githubLoggedIn
						);
					} else if (error.response) {
						//non 2xx response
						this.branchUpdateStatus.error = true;
						if (error?.response?.status === 404 || error?.response?.status === 403) {
							this.branchUpdateStatus.message += "Repository not found or access denied.";
						}
					} else if (error.request) {
						//no response
						this.branchUpdateStatus.message += "Please check your internet connection";
					} else {
						//making request failed
						this.branchUpdateStatus.message += "Please check your internet connection";
					}
				} else {
					this.branchUpdateStatus.message += "Unknown error occurred";
				}
			} finally {
				this.branchUpdateStatus.loading = false;
			}
		},
		/*
		 * Setters
		 */
		async setWorkingBranch(branchName: string, commitHash: string) {
			if (commitHash === "") {
				commitHash = "HEAD";
			}

			const changed = branchName !== this.repository.Branch || commitHash !== this.repository.Commit;

			if (changed) {
				this.buildDirectoryUpdateStatus.loading = true;

				try {
					await this.repositoriesStore.setRepositoryWorkingBranch(
						this.repositoryID,
						branchName,
						commitHash,
						this.branchUpdateStatus
					);

					await this.getConfigFile();

					/*
					 *	When the branch changes we have no guarantee that the same paths exist, so we should check if
					 *	the path exists, if not check it src exists. if neither exists, reset to root
					 */

					let validBuildPath = "";

					// Try to get the current build dir contents from github
					try {
						const response = await githubClient.getRepoDirectories(
							this.repository.RemoteURL.split("github.com/")[1],
							this.repository.BuildDirectory,
							this.repository?.Branch
						);
						validBuildPath = this.repository.BuildDirectory;
					} catch (error) {
						monitoringCaptureError(error, "Set repository working branch");
						// if errors assume that path couldn't be found
					}

					// Try to get the contexts of "./src" from github
					if (validBuildPath === "") {
						try {
							const response = await githubClient.getRepoDirectories(
								this.repository.RemoteURL.split("github.com/")[1],
								"src",
								this.repository?.Branch
							);
							validBuildPath = "src";
						} catch (error) {
							monitoringCaptureError(error, "Set repository working branch");
							// if errors assume that path couldn't be found
						}
					}

					// if the valid build found is different to whats in db... update
					if (validBuildPath !== this.repository.BuildDirectory) {
						this.setBuildDirectory("src").finally(() => {
							this.buildDirectoryUpdateStatus.loading = false;
						});
						this.directoryTreeViewItems = [];
						this.openTrees = [];
					} else {
						this.buildDirectoryUpdateStatus.loading = false;
					}
				} catch (error) {
					monitoringCaptureError(error, "Set repository working branch");
					// no need to handle the error because it would be handled inside repo store
				}
			}
		},
		async setActiveCoreID(coreID: string) {
			if (!this.repositoryID) {
				return;
			}

			this.closeSelectCoreDialog();

			if (coreID !== this.repository.Core) {
				try {
					await this.repositoriesStore.setRepositoryCoreID(this.repositoryID, coreID, this.coreUpdateStatus);
				} catch (error) {
					monitoringCaptureError(error, "Set repository core");
					// no need to handle the error because it would be handled inside repo store
				}
			}
		},
		async setRunArguments(data: { runArguments: string }) {
			if (!this.repositoryID) {
				return;
			}

			this.closeRunArgumentsDialog();

			if (data.runArguments !== this.repository.Arguments) {
				try {
					await this.repositoriesStore.setRepositoryRunArguments(
						this.repositoryID,
						data.runArguments,
						this.runArgumentsUpdateStatus
					);
				} catch (error) {
					monitoringCaptureError(error, "Set repository run arguments");
					// no need to handle the error because it would be handled inside repo store
				}
			}
		},

		/*
		 *	Sets the BuildDirectory of the Repository object in the SCCE API via the repositoriesStore.
		 */
		async setBuildDirectory(buildDirectory: string) {
			if (buildDirectory !== this.repository.BuildDirectory) {
				try {
					await this.repositoriesStore.setRepositoryBuildDirectory(
						this.repositoryID,
						buildDirectory,
						this.buildDirectoryUpdateStatus
					);
				} catch (error) {
					monitoringCaptureError(error, "this.repositoriesStore.setRepositoryBuildDirectory");
					// no need to handle the error because it would be handled inside repo store
					// Comment by @KomaGR: Because of how repositoriesStore.setRepositoryBuildDirectory is implemented,
					// the above error is always null.
				}
			}
		},

		openRunArgumentsDialog() {
			this.showRunArgumentsDialog = true;
		},
		closeRunArgumentsDialog() {
			this.showRunArgumentsDialog = false;
		},

		openSelectCoreDialog() {
			this.showSelectCoreDialog = true;
		},
		closeSelectCoreDialog() {
			this.showSelectCoreDialog = false;
		},

		closeDirectoryDialog() {
			this.showDirectoryDialog = false;
		},

		switchToReferenceCore() {
			this.setActiveCoreID(this.coresStore.defaultReferenceCore.CoreID);
		},

		/*
		 * Functions for the directory tree
		 */
		openDirectoryTree() {
			this.showDirectoryDialog = true;
		},
		selectedBuildDirectoryUpdateHandler(newSelectedBuildDirectory: string) {
			this.selectedBuildDirectory = newSelectedBuildDirectory; // Set value itself to emulate v-model
			this.setBuildDirectory(this.selectedBuildDirectory); // then set the BuildDirectory of the actual repo
			this.closeDirectoryDialog();
		},

		async setMountConfig(mountConfig: null | undefined | LocalMountConfig) {
			if (!this.repositoryID) {
				return;
			}

			this.closeMountDataSourcesDialog();

			//TODO: Add a guard here to only update db if different
			// const newConfigIsDifferent =
			// 	this.repositoryFromDB &&
			// 	(mountConfig !== this.repositoryFromDB.DataSources ||
			// 		(mountConfig === null && this.repositoryFromDB.DataSources === []));

			try {
				await this.repositoriesStore.setRepositoryDataSources(
					this.repositoryID,
					mountConfig,
					this.dataSourceUpdateStatus
				);
			} catch (error) {
				monitoringCaptureError(error, "Set repository mount config");
				// no need to handle the error because it would be handled inside repo store
			}
		},
		openMountDataSourcesDialog() {
			this.showMountDataSourcesDialog = true;
		},
		closeMountDataSourcesDialog() {
			this.showMountDataSourcesDialog = false;
		},
		async disconnectRepository() {
			this.repoDisconnectStatus.loading = true;
			try {
				await this.repositoriesStore.disconnectFromRepository(this.repositoryID);
				setTimeout(async () => {
					this.repoDisconnectStatus.loading = false;
				}, 1000);
			} catch (error) {
				monitoringCaptureError(error, "Disconnect repository");
				if (axios.isAxiosError(error)) {
					this.repoDisconnectStatus.error = true;
					this.repoDisconnectStatus.message = "An error occurred while disconnecting from the repository.";
				}
				this.repoDisconnectStatus.loading = false;
			}
			this.$emit("repository-disconnected", { repositoryID: this.repositoryID });
		},

		async submitBuildRequest() {
			this.buildAccessState = {
				loading: false,
				status: undefined,
				error: false,
				message: "",
			};
			this.taskAccessState = {
				loading: false,
				error: false,
				message: "",
			};
			this.activeBuild = undefined;
			this.activeTask = undefined;
			this.$emit("clear-results");
			this.showBuildBar = true;
			// this.variableDiscoveryInProgress = true;
			this.activeBuild = { Status: BuildStatusE.Accepted };
			try {
				const traceVariables = this.variableTraceList?.length ? this.variableTraceList : undefined;

				const coreID = this.repository?.Core ?? this.coresStore.defaultCoreID;
				const userPrimaryOrganization = await this.userStore.getCurrentUserPrimaryOrganization();

				const response = await signaloidClient.startRepositoryBuild(
					this.repositoryID,
					{
						CoreID: coreID,
						TraceVariables: traceVariables ?? [],
					},
					userPrimaryOrganization ?? undefined
				);
				this.lastBuildForVariableDiscovery = false;

				const buildResponse = await signaloidClient.getBuildByID(response.data.BuildID);
				if (response.status == 202) {
					this.activeBuild = buildResponse.data;

					if (!this.activeBuild?.BuildID) {
						throw new Error("Build ID not returned from API");
					}

					console.log(`Build created ID: ${this.activeBuild?.BuildID}`);

					// @ts-ignore
					this.$posthog?.capture("build_created", {
						buildType: "SourceCode",
						buildID: this.activeBuild?.BuildID,
					});
					if (this.buildEventBusSub == undefined) {
						// Is this necessary?
						this.subscribeToBuildEventBus();
					}

					this.buildsStore.addToActiveBuildList(this.activeBuild as Build);
					this.buildsStore.subscribeToBuild(this.activeBuild.BuildID);
				} else {
					throw new Error("Unknown error: failed to create build on server");
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						console.warn("Non 2xx response:", error.response);

						let severityLevel: Sentry.SeverityLevel = "error";

						if (error.response.status === 504) {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.Error;
							}

							this.buildAccessState.message =
								"The build request timed out." +
								" Please try starting the build again. If this error persists," +
								" please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 502 || error.response.status === 500) {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.Error;
							}

							this.buildAccessState.message =
								"Our system encountered an internal error while starting the build." +
								" Our team will receive a high-priority notification for this issue." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
							severityLevel = "fatal";
						} else if (error.response.status === 503) {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.Error;
							}

							this.buildAccessState.message =
								"Our systems are currently experiencing an unusually large number of requests and cannot accept new builds at the moment." +
								" We are hard at work scaling up our infrastructure. Please try again later." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status >= 500) {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.Error;
							}

							this.buildAccessState.message =
								`Our system encountered a server error while starting the build (HTTP Code ${error.response.status}).` +
								" Please try starting the build again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 401) {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.Error;
							}

							this.buildAccessState.message =
								"Our system encountered an authorization error while starting the build." +
								" Signing out and back in will ensure you are properly authorized." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 403 || error.response.status === 402) {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.TierLimited;
							}

							if (error.response.data.message.toLowerCase().includes("dynamic instruction")) {
								this.buildAccessState.message =
									"You have reached the monthly computation limit for your account." +
									" Please upgrade your account in https://signaloid.io/billing to run more builds." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.DynamicInstructionCount],
								});
							} else {
								this.buildAccessState.message =
									"You have reached the limit of available concurrently-running builds for your account." +
									" Please upgrade your account in https://signaloid.io/billing to enable builds running concurrently." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.ConcurrentBuildCount],
								});
							}
						} else if (error.response.status >= 400) {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.Error;
							}

							this.buildAccessState.message =
								`Our system encountered an error while starting the new build (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the build again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else {
							if (this.activeBuild) {
								this.activeBuild.Status = BuildStatusE.Error;
							}

							this.buildAccessState.message =
								`Our system encountered an error while starting the new build (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the build again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						}

						this.buildAccessState.error = true;

						Sentry.captureException(new Error("Build rejected: Non 2xx response"), {
							level: severityLevel,
							extra: { error: error, userMessage: this.buildAccessState.message },
							tags: { service: "create-build" },
						});
					} else if (error.request) {
						if (this.activeBuild) {
							this.activeBuild.Status = BuildStatusE.Error;
						}

						this.buildAccessState.error = true;
						this.buildAccessState.message =
							"A build request was initiated but there was no response from our servers." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Build failed: Request Failed"), {
							extra: { error: error, userMessage: this.buildAccessState.message },
							tags: { service: "create-build" },
						});
					} else {
						if (this.activeBuild) {
							this.activeBuild.Status = BuildStatusE.Error;
						}

						this.buildAccessState.error = true;
						this.buildAccessState.message =
							"A browser error occurred when trying to create the build request." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Build failed: Preflight error"), {
							extra: { error: error, userMessage: this.buildAccessState.message },
							tags: { service: "create-build" },
						});
					}
				} else {
					if (this.activeBuild) {
						this.activeBuild.Status = BuildStatusE.Error;
					}

					this.buildAccessState.error = true;
					this.buildAccessState.message =
						"The build request could not be completed due to an unexpected error." +
						" If this error persists, please contact support at developer-support@signaloid.com.";
					Sentry.captureException(new Error("Build failed: Unknown error"), {
						extra: { error: error, userMessage: this.buildAccessState.message },
						tags: { service: "create-build" },
					});
				}
			}
		},
		async submitTaskRequest() {
			if (!this.activeBuild || !this.activeBuild.BuildID) {
				return;
			}
			try {
				// if the user has not selected a data source... switch to default
				const dataSources =
					this.repository?.DataSources ??
					(await dsUtil.formatDataSourcesPayload(
						this.defaultRepoMountConfig?.ResourceID,
						this.defaultRepoMountConfig?.ResourceType,
						this.defaultRepoMountConfig?.Location
					));
				const args = this.repository.Arguments;

				const userPrimaryOrganization = await this.userStore.getCurrentUserPrimaryOrganization();
				const response = await signaloidClient.startBuildTask(
					this.activeBuild.BuildID,
					{
						Arguments: args,
						DataSources: dataSources ?? [],
					},
					userPrimaryOrganization ?? undefined
				);

				if (response.status == 202) {
					this.activeTask = response.data;

					if (!this.activeTask.TaskID) {
						throw new Error("Task ID not returned from API");
					}

					console.info(`Task created with TaskID '${this.activeTask.TaskID}'`);

					// @ts-ignore
					this.$posthog?.capture("task_created", {
						taskType: "Repository",
						taskId: this.activeTask.TaskID,
					});

					if (this.taskEventBusSub == undefined) {
						this.subscribeToTaskEventBus();
					}

					// @ts-ignore - FIXME: this is because active TASK is a partial type
					this.tasksStore.addToActiveTaskList(this.activeTask);
					this.tasksStore.subscribeToTaskV2(this.activeTask?.TaskID as string);
				} else {
					// TODO: handle error
					throw new Error("Unknown error occurred when creating task.");
				}
				/*
				 * At this point the task has been accepted and will be queued to be run.
				 * Any subsequent tasks will return with 403 if the concurrent task limit has been reached.
				 * The task status updates will be handled by `taskEventBusCallback`
				 */
			} catch (error) {
				monitoringCaptureError(error, "Create repository task");

				if (axios.isAxiosError(error)) {
					if (error.response) {
						//non 2xx response
						console.warn("Non 2xx response:", error.response);
						let severityLevel: Sentry.SeverityLevel = "error";

						if (error.response.status === 504) {
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.Error;
							}
							this.taskAccessState.message =
								" The task request timed out." +
								" Please try starting the task again. If this error persists," +
								" please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 502 || error.response.status === 500) {
							/*
							 *	A 502 error 99.9% means that a Lambda is failing (the way AWS is currently set up)
							 */
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.Error;
							}
							this.taskAccessState.message =
								"Our system encountered an internal error while starting the task." +
								" Our team will receive a high priority notification for this issue." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
							severityLevel = "fatal"; // This is fatal because it usually means that a Lambda is crashing.
						} else if (error.response.status === 503) {
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.Error;
							}
							this.taskAccessState.message =
								"Our systems are currently experiencing an unusually large number of requests and cannot accept new tasks at the moment." +
								" We are hard at work scaling up our infrastructure. Please try again later." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status >= 500) {
							/*
							 *	Other 5xx response
							 */
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.Error;
							}
							this.taskAccessState.message =
								`Our system encountered a server error while starting the task (HTTP Code ${error.response.status}).` +
								" Please try starting the task again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 401) {
							/*
							 *	Axios interceptors should deal with this and
							 *	silently re-authenticate the user. If that
							 *	fails, we end up here.
							 */
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.Error;
							}
							this.taskAccessState.message =
								"Our system encountered an authorization error while starting the task." +
								" Signing out and back in will ensure you are properly authorized." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 403 || error.response.status === 402) {
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.TierLimited;
							}

							if (error.response.data.message.toLowerCase().includes("dynamic instruction")) {
								this.taskAccessState.message =
									"You have reached the monthly dynamic instruction allowance for your account." +
									" Please upgrade your account in https://signaloid.io/billing to run more tasks." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.DynamicInstructionCount],
								});
							} else {
								this.taskAccessState.message =
									"You have reached the limit of available concurrently-running tasks for your account." +
									" Please upgrade your account in https://signaloid.io/billing to enable tasks running concurrently." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.ConcurrentTaskCount],
								});
							}
						} else if (error.response.status >= 400) {
							/*
							 *	Other 4xx response
							 */
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.Error;
							}
							this.taskAccessState.message =
								`Our system encountered an error while starting the new task (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the task again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else {
							/*
							 *	Other HTTP errors
							 */
							if (this.activeTask) {
								this.activeTask.Status = TaskStatusE.Error;
							}
							this.taskAccessState.message =
								`Our system encountered an error while starting the new task (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the task again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						}

						this.taskAccessState.error = true;

						Sentry.captureException(new Error("Task rejected: Non 2xx response"), {
							level: severityLevel,
							extra: { error: error, userMessage: this.taskAccessState.message },
							tags: { service: "create-task" },
						});
					} else if (error.request) {
						//no response
						if (this.activeTask) {
							this.activeTask.Status = TaskStatusE.Error;
						}
						this.taskAccessState.error = true;
						this.taskAccessState.message =
							"A task request initiated but there was no response from our servers." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Task failed: Request Failed"), {
							extra: { error: error, userMessage: this.taskAccessState.message },
							tags: { service: "create-task" },
						});
					} else {
						//making request failed
						if (this.activeTask) {
							this.activeTask.Status = TaskStatusE.Error;
						}
						this.taskAccessState.error = true;
						this.taskAccessState.message =
							"A browser error occurred when trying to create the task request." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Task failed: Preflight error"), {
							extra: { error: error, userMessage: this.taskAccessState.message },
							tags: { service: "create-task" },
						});
					}
				} else {
					if (this.activeTask) {
						this.activeTask.Status = TaskStatusE.Error;
					}
					this.taskAccessState.error = true;
					this.taskAccessState.message =
						"The task request could not be completed due to an unexpected error." +
						" If this error persists, please contact support at developer-support@signaloid.com.";
					Sentry.captureException(new Error("Task failed: Unknown error"), {
						extra: { error: error, userMessage: this.taskAccessState.message },
						tags: { service: "create-task" },
					});
				}
			}
		},
		async openExecutionLog() {
			try {
				if (this.activeTask?.TaskID && this.activeTask?.Status && isTaskTerminated(this.activeTask.Status)) {
					this.$emit("open-execution-log", {
						taskID: this.activeTask.TaskID,
						buildID: this.activeBuild?.BuildID ?? "",
					});
				}
				if (
					this.activeBuild?.BuildID &&
					this.activeBuild?.Status &&
					isBuildTerminated(this.activeBuild.Status)
				) {
					this.$emit("open-execution-log", {
						taskID: "",
						buildID: this.activeBuild?.BuildID,
					});
				}
			} catch (error) {
				monitoringCaptureError(error, "Open repository execution log");
				this.taskResultsFetchStatus.error = true;
				this.taskResultsFetchStatus.message = error as string;
			} finally {
				this.taskResultsFetchStatus.loading = false;
			}
		},
		async cancelActiveTask() {
			if (this.activeTask?.TaskID) {
				try {
					await signaloidClient.cancelTaskByID(this.activeTask.TaskID);
				} catch (error) {
					monitoringCaptureError(error, "Cancel active task");
				}
			}
		},
		async cancelActiveBuild() {
			if (this.activeBuild?.BuildID) {
				try {
					await signaloidClient.cancelBuildByID(this.activeBuild?.BuildID);
				} catch (error) {
					monitoringCaptureError(error, "Cancel active build");
				}
			}
		},
		openRepositoryDetailsPage() {
			this.$router.push({
				name: "RepositoryDetail",
				query: { repositoryID: this.repository.RepositoryID },
			});
		},
		async discoverVariables() {
			// FIXME: use post project end point to build and run on the default core and get the variable list
			// TODO: Update this to use Modules endpoint or tasks with build only query param.
			// TODO: Consider moving this to the repo details page

			try {
				const userPrimaryOrganization = await this.userStore.getCurrentUserPrimaryOrganization();

				const response = await signaloidClient.startRepositoryDiscoverVariablesBuild(
					this.repositoryID,
					{
						CoreID: this.activeCore?.CoreID,
					},
					userPrimaryOrganization ?? undefined
				);
				this.lastBuildForVariableDiscovery = true;

				const buildResponse = await signaloidClient.getBuildByID(response.data.BuildID);
				if (response.status == 202) {
					this.activeBuild = buildResponse.data;

					if (!this.activeBuild?.BuildID) {
						throw new Error("Build ID not returned from API");
					}

					console.log(`Build created ID: ${this.activeBuild?.BuildID}`);
					// @ts-ignore
					this.$posthog?.capture("build_created", {
						buildType: "Repository",
						buildID: this.activeBuild?.BuildID,
					});

					if (this.buildEventBusSub == undefined) {
						// Is this necessary?
						this.subscribeToBuildEventBus();
					}

					this.buildsStore.addToActiveBuildList(this.activeBuild as Build);
					this.buildsStore.subscribeToBuild(this.activeBuild?.BuildID);
				} else {
					throw new Error("Unknown error: failed to create build on server");
				}
			} catch (error) {
				monitoringCaptureError(error, "Discover variables");
			}
		},
		async buildEventBusCallback(event: BuildBusEvent) {
			// @ts-ignore FIXME:
			const buildID = this.activeBuild?.BuildID;
			if (buildID && event.buildData.BuildID === buildID) {
				this.activeBuild = event.buildData;
				const buildStatus = event.buildData.Status;

				try {
					if (buildStatus && canFetchVariables(buildStatus)) {
						// fetch task variables
						this.buildsStore.fetchAndParseVariables(buildID).then((result) => {
							this.$emit("repository-variables-updated", result);
						});
					}
				} catch (error) {
					monitoringCaptureError(error, "Handle active repository task status change");
				}

				const variableDiscoveryFail = isBuildFail(buildStatus) && this.lastBuildForVariableDiscovery;
				const buildTerminated = isBuildTerminated(buildStatus) && !this.lastBuildForVariableDiscovery;
				const buildFailed = isBuildFail(buildStatus) && !this.lastBuildForVariableDiscovery;
				const successBuildExecution = buildStatus == "Completed" && !this.lastBuildForVariableDiscovery;
				if (successBuildExecution) {
					// Optimistic Status Update
					if (this.activeTask) {
						this.activeTask.Status = TaskStatusE.Accepted;
					}
					this.submitTaskRequest();
				}
				if (variableDiscoveryFail || buildTerminated) {
					try {
						await this.outputsStore.loadBuildOutputs(buildID);
					} catch (error) {
						monitoringCaptureError(error, "Handle terminated repository build status change");
					}
				}
				if (buildFailed) {
					this.openExecutionLog();
				}
			}
		},
		async taskEventBusCallback(event: TaskBusEvent) {
			if (this.activeTask?.TaskID && event.taskData.TaskID === this.activeTask?.TaskID) {
				this.activeTask = event.taskData;
				if (this.activeTask?.Status && isTaskTerminated(this.activeTask.Status)) {
					this.openExecutionLog();
				}
			}
		},
		subscribeToTaskEventBus() {
			this.taskEventBusSub = this.tasksStore.taskEventBus.on(this.taskEventBusCallback);
		},
		subscribeToBuildEventBus() {
			this.buildEventBusSub = this.buildsStore.buildEventBus.on(this.buildEventBusCallback);
		},
		async getConfigFile() {
			let fileContent: undefined | string = undefined;
			let fileEncoding: undefined | string = undefined;
			this.repoConfigFileData = null;
			this.repoConfigFilePath = null;

			try {
				const configFile = await githubClient.detectSignaloidConfigFile(
					this.repositoryFullName,
					this.repository.Branch
				);
				if (!configFile) {
					/*
					 *	No config file found in repository
					 */
					return;
				}

				if (configFile.content) {
					fileContent = configFile.content;
					fileEncoding = "base64";
				} else {
					const response = await githubClient.getRepoConfigFile(
						this.repositoryFullName,
						this.repository?.Branch,
						configFile.path
					);

					// @ts-ignore
					fileContent = response.data.content;
					// @ts-ignore
					fileEncoding = response.data.encoding;
				}
				this.repoConfigFilePath = configFile.path;
			} catch (error) {
				monitoringCaptureError(error, "getConfigFile:detectSignaloidConfigFile+getRepoConfigFile");
				return;
			}

			if (!fileContent) {
				throw new Error("fileContent is empty but we had already found it");
			}

			/*
			 *	Parse the Signaloid config file
			 */
			try {
				// Check if the file is base64 encoded and decode if needed
				if (fileEncoding == "base64") {
					fileContent = base64Decode(fileContent);
				}

				this.repoConfigFileData = YAML.parse(fileContent);

				// Sanitize minimum core requirements by removing invalid ones
				if (this.repoConfigFileData && "MinimumCore" in this.repoConfigFileData) {
					// Iterate through minimum core requirements
					for (const property in this.repoConfigFileData.MinimumCore) {
						const value = this.repoConfigFileData.MinimumCore[property];
						// Check if this property valid
						if (!(property in SignaloidConfigFileMinimumCoreOptions)) {
							delete this.repoConfigFileData.MinimumCore[property];
							this.repoConfigFileWarnings.push("Invalid parameter " + property);
							continue;
						}
						// Check if this property value is valid
						if (SignaloidConfigFileMinimumCoreOptions[property].type == "number") {
							if (
								value < SignaloidConfigFileMinimumCoreOptions[property].min ||
								value > SignaloidConfigFileMinimumCoreOptions[property].max
							) {
								delete this.repoConfigFileData.MinimumCore[property];
								this.repoConfigFileWarnings.push(
									"Invalid value '" +
										value +
										"' for property '" +
										property +
										"'. Value must be within range: [" +
										SignaloidConfigFileMinimumCoreOptions[property].min +
										", " +
										SignaloidConfigFileMinimumCoreOptions[property].max +
										"]."
								);
								continue;
							}
						}
						// Check if this property value is valid
						if (SignaloidConfigFileMinimumCoreOptions[property].type == "option") {
							if (!SignaloidConfigFileMinimumCoreOptions[property].options.includes(value)) {
								delete this.repoConfigFileData.MinimumCore[property];

								this.repoConfigFileWarnings.push(
									"Invalid value '" +
										value +
										"' for property '" +
										property +
										"'. Valid values are: [" +
										SignaloidConfigFileMinimumCoreOptions[property].options.join(", ") +
										"]."
								);
								continue;
							}
						}
					}
				}
			} catch (error) {
				console.log(error);
				if (error instanceof YAMLError && error instanceof YAMLParseError) {
					/*
					 *	If this was a YAMLError most likely it's the user's
					 *	fault (or whoever's created the YAML file).
					 */
					this.repoConfigFileError = error;
					this.repoConfigFileData = null;
				} else {
					this.repoConfigFileData = null;
					this.repoConfigFilePath = null;
					// Only log non-YAMLError to Sentry
					monitoringCaptureError(error, "Get repository config file");
				}
			}
		},
	},
	computed: {
		...mapState(useDataSourcesStore, {
			defaultRepoMountConfig: "defaultMountConfig",
		}),
		...mapState(useCoresStore, {
			filteredVisibleCoresList: "filteredVisibleCoresList",
			coresSatisfyMinConfig: "coresSatisfyMinConfig",
		}),
		hasTerminatedTaskOrBuild(): boolean {
			const hasTerminatedTask = Boolean(this.activeTask?.Status && isTaskTerminated(this.activeTask.Status));

			const hasFailedBuild = Boolean(this.activeBuild?.Status && isBuildFail(this.activeBuild.Status));

			return hasTerminatedTask || hasFailedBuild;
		},
		repoUniqueName(): string {
			if (this.remoteURLHost === "GitHub") {
				// return this.repositoryFromDB.RemoteURL.split("github.com/")[1];
				return this.repository.RemoteURL;
			}
			return this.repositoryID;
		},
		repoPlaceholderName(): string {
			if (this.remoteURLHost === "GitHub") {
				return this.repository.RemoteURL.split("github.com/")[1];
			}
			return "Repository Loading...";
		},
		remoteURLHost(): string {
			const remoteURL = new URL(this.repository.RemoteURL);
			// FIX: Maybe returning pretty string for GitHub?
			if (remoteURL.host.includes("github.com")) {
				return "GitHub";
			} else {
				return remoteURL.hostname;
			}
		},
		/*
		 *	Returns the "$owner/$repo" of the current repository
		 */
		repositoryFullName(): string {
			const repoRemoteURL = new URL(this.repository.RemoteURL);
			/*
			 *	Slice removes the "/" prefix
			 */
			return repoRemoteURL.pathname.slice(1);
		},
		chosenCore(): Core | undefined {
			if (this.repository?.Core) {
				const activeCoreID = this.repository.Core;
				const chosenCore = this.filteredVisibleCoresList.find((core) => core.CoreID === activeCoreID);
				return chosenCore;
			}
			return undefined;
		},
		chosenDataSources(): DataSource[] | undefined {
			if (this.repository?.DataSources) {
				return this.repository.DataSources;
			}
			return undefined;
		},
		dataSourceChipStyle(): { icon: string; color: string; tooltip: string } {
			if (this.chosenDataSources === undefined) {
				return {
					icon: "mdi-database-cog-outline",
					color: "primary",
					tooltip: "Using default data sources specified in settings.",
				};
			} else if (this.chosenDataSources.length === 0) {
				return {
					icon: "mdi-database-off-outline",
					color: "grey",
					tooltip: "No data sources.",
				};
			} else {
				return {
					icon: "mdi-database-arrow-right-outline",
					color: "primary",
					tooltip: `${this.chosenDataSources.length} data source${
						this.chosenDataSources.length > 1 ? "s" : ""
					} mounted.`,
				};
			}
		},
		buildDirectoryPrettyString(): string {
			if (this.repository.BuildDirectory === "") {
				return "./";
			} else if (this.repository.BuildDirectory) {
				return this.repository.BuildDirectory;
			} else {
				return "-";
			}
		},
		activeCore(): Core | undefined {
			if (this.chosenCore) {
				return this.chosenCore;
			}

			/*
			 *	If chosen core is undefined, then it has either been deleted, or
			 *	is one of the hidden ones, as a result, we will update the core
			 *	and use the default one.
			 */
			const defaultCore = this.coresStore.defaultCore;
			this.setActiveCoreID(defaultCore.CoreID);
			return defaultCore;
		},
		coreUsedForExecution(): string | undefined {
			return this.repository?.Core ?? this.coresStore.defaultCoreID;
		},
		coreChipStyle(): ChipStyle {
			// If there is no min core config
			if (!this.repoConfigFileData?.MinimumCore) {
				// the core is specified in the repo
				if (this.repository?.Core) {
					return { color: "primary", outlined: true, textColor: "primary" };
				} else {
					// else the repo will use default core
					return { color: "grey", outlined: true, textColor: "grey" };
				}
			}

			if (!this.activeCore) {
				return { color: "grey", outlined: true, textColor: "grey" };
			}
			// get the default core
			return !this.coresSatisfyMinConfig(this.repoConfigFileData.MinimumCore)[this.activeCore.CoreID]
				? { color: "warning", outlined: true, textColor: "warning" }
				: { color: "primary", outlined: true, textColor: "primary" };
		},
		coreListTextColor(): string | undefined {
			if (!this.repoConfigFileData?.MinimumCore || !this.activeCore) {
				return undefined;
			}

			return !this.coresSatisfyMinConfig(this.repoConfigFileData.MinimumCore)[this.activeCore.CoreID]
				? "grey--text"
				: "primary--text";
		},
	},
	created() {
		this.coresStore.getCoresFilteredCoresByUserTier();
	},
	async beforeMount() {
		const availableCoreCoreID = this.coresStore.resetCoreIdIfRestricted(this.repository.Core);
		if (availableCoreCoreID != this.repository.Core) {
			/*
			 *	Force-reset the repository Core if the configured CoreID is not
			 *	available or restricted.
			 */
			await this.repositoriesStore.setRepositoryCoreID(
				this.repositoryID,
				availableCoreCoreID,
				this.coreUpdateStatus
			);
		}

		// Always fetch repo data before mount.
		this.selectedBuildDirectory = this.repository.BuildDirectory;

		/*
		 *	Wait until GitHub details are fetched and then fetch repositories
		 *	(to make sure auth is correctly set)
		 */
		await this.getRepoDataFromRemote();

		// TODO: Check for active tasks matching this repo ID, and add sub if matches
	},
	beforeDestroy() {
		/*
		 *	Unsubscribe from the task even bus on dismount
		 */
		if (this.taskEventBusSub !== undefined) {
			this.taskEventBusSub();
		}
		if (this.buildEventBusSub !== undefined) {
			this.buildEventBusSub();
		}
	},
});
