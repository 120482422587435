var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticStyle: {
        "border-bottom": "none",
        "border-left": "none",
        "border-right": "none",
      },
      attrs: { height: "50px", rounded: "b-lg", outlined: "" },
    },
    [
      _c(
        "v-container",
        { staticClass: "fill-height py-0 px-2", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              attrs: {
                justify: "space-between",
                align: "center",
                "no-gutters": "",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto", "align-self": "center" } },
                [
                  _c("LimitableActionButton", {
                    attrs: {
                      color: "accent",
                      loading: _vm.actionButtonLoading,
                      tooltipText: "Compile and Run",
                      "data-test": "build-and-run-button",
                      "limits-to-check": [
                        _vm.UserLimitsE.ConcurrentTaskCount,
                        _vm.UserLimitsE.DynamicInstructionCount,
                      ],
                      "btn-icon": "mdi-play",
                    },
                    on: { "valid-click": _vm.postAsJob },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-sm-2 px-1 font-weight-light text-body-2" },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        justify: _vm.$vuetify.breakpoint.mobile
                          ? "space-around"
                          : "center",
                        align: "center",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mr-sm-2 mr-1",
                          attrs: { cols: "5", sm: "auto" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                align: "center",
                                "align-content": "end",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  class:
                                    _vm.buildID !== undefined
                                      ? "ma-0 pa-0 mb-n2 mb-sm-0 mt-1 mt-sm-0"
                                      : "",
                                  attrs: {
                                    cols: "12",
                                    sm: "auto",
                                    "data-test": "build-id-label",
                                  },
                                },
                                [_vm._v(" Build:" + _vm._s("\xa0") + " ")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ma-0 pa-0 flex-nowrap",
                                  attrs: { cols: "12", sm: "auto" },
                                },
                                [
                                  _vm.buildID !== undefined
                                    ? _c("BuildIDDisplay", {
                                        attrs: {
                                          buildID: _vm.buildID,
                                          showClipboardButton: true,
                                        },
                                      })
                                    : _c("div", {
                                        staticStyle: { "min-width": "50px" },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "ml-sm-2 ml-0",
                          attrs: {
                            cols: _vm.buildIsActive ? "4" : "5",
                            sm: "auto",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                align: "center",
                                "align-content": "center",
                                "no-gutters": "",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  class:
                                    _vm.buildStatus !== undefined
                                      ? "ma-0 pa-0 mb-0 mb-sm-0 mt-n1 mt-sm-auto"
                                      : "",
                                  attrs: {
                                    cols: "12",
                                    sm: "auto",
                                    "data-test": "project-status-label",
                                  },
                                },
                                [
                                  _vm._v(" Status:" + _vm._s("\xa0") + " "),
                                  _vm.buildAccessState?.error
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            "open-delay": "250",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              size: "14",
                                                              color: "warning",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-alert-outline "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            311381781
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.buildAccessState.message
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.buildStatusText
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0 pa-0 flex-nowrap",
                                      attrs: { cols: "12", sm: "auto" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: `font-weight-regular ${_vm.buildStatusColor}--text `,
                                          attrs: {
                                            "data-test": "project-status-value",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.buildStatusText))]
                                      ),
                                      _vm.activeBuildStatus
                                        ? _c("v-progress-linear", {
                                            attrs: {
                                              indeterminate: "",
                                              height: "2",
                                              color: _vm.buildStatusColor,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c("div", {
                                    staticStyle: { "min-width": "50px" },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.buildStatusMessageOnError
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mr-sm-2 mr-1 ml-3",
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile
                                  ? "12"
                                  : "4",
                                sm: "auto",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    "align-content": "end",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.buildID !== undefined
                                          ? "ma-0 pa-0 mb-n2 mb-sm-0 mt-1 mt-sm-0"
                                          : "",
                                      attrs: {
                                        cols: "12",
                                        sm: "auto",
                                        "data-test": "build-id-label",
                                      },
                                    },
                                    [_vm._v(" Error:" + _vm._s("\xa0") + " ")]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0 pa-0 flex-nowrap",
                                      attrs: { cols: "12", sm: "auto" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "ma-0 pa-0 flex-nowrap",
                                          attrs: { cols: "12", sm: "auto" },
                                        },
                                        [
                                          _c(
                                            "code",
                                            {
                                              staticClass:
                                                "font-weight-light my-3 align-center",
                                              staticStyle: {
                                                "font-size": "0.875em",
                                                "min-width": "50px",
                                                "min-height": "36px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.buildStatusMessageOnError
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.buildIsActive
                        ? _c(
                            "v-col",
                            { attrs: { cols: "1", sm: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-sm-3 ml-n1",
                                  attrs: {
                                    color: "secondary",
                                    elevation: "0",
                                    outlined: "",
                                    height: _vm.$vuetify.breakpoint.mobile
                                      ? "25px"
                                      : "20px",
                                    "x-small": "",
                                  },
                                  on: { click: _vm.sendCancelActiveBuild },
                                },
                                [
                                  !_vm.$vuetify.breakpoint.mobile
                                    ? [_vm._v(" Cancel ")]
                                    : _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-cancel "),
                                      ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.buildStatusMessageOnError
                ? _c(
                    "v-col",
                    {
                      staticClass: "px-sm-2 px-1 font-weight-light text-body-2",
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            justify: _vm.$vuetify.breakpoint.mobile
                              ? "space-around"
                              : "center",
                            align: "center",
                          },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mr-sm-2 mr-1",
                              attrs: { cols: "5", sm: "auto" },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    "align-content": "end",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.taskID !== undefined
                                          ? "ma-0 pa-0 mb-n2 mb-sm-0 mt-1 mt-sm-0"
                                          : "",
                                      attrs: {
                                        cols: "12",
                                        sm: "auto",
                                        "data-test": "task-id-label",
                                      },
                                    },
                                    [_vm._v(" Task:" + _vm._s("\xa0") + " ")]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0 pa-0 flex-nowrap",
                                      attrs: { cols: "12", sm: "auto" },
                                    },
                                    [
                                      _vm.taskID !== undefined
                                        ? _c("TaskIDDisplay", {
                                            attrs: {
                                              taskID: _vm.taskID,
                                              link: {
                                                name: "TaskDetail",
                                                params: { id: _vm.taskID },
                                              },
                                              showClipboardButton: true,
                                            },
                                          })
                                        : _c("div", {
                                            staticStyle: {
                                              "min-width": "50px",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-sm-2 ml-0",
                              attrs: {
                                cols: _vm.taskIsActive ? "4" : "5",
                                sm: "auto",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    align: "center",
                                    "align-content": "center",
                                    "no-gutters": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.taskStatus !== undefined
                                          ? "ma-0 pa-0 mb-0 mb-sm-0 mt-n1 mt-sm-auto"
                                          : "",
                                      attrs: {
                                        cols: "12",
                                        sm: "auto",
                                        "data-test": "project-status-label",
                                      },
                                    },
                                    [
                                      _vm._v(" Status:" + _vm._s("\xa0") + " "),
                                      _vm.taskAccessState?.error
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                "open-delay": "250",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  size: "14",
                                                                  color:
                                                                    "warning",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " mdi-alert-outline "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                311381781
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.taskAccessState.message
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.taskStatusText
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "ma-0 pa-0 flex-nowrap",
                                          attrs: { cols: "12", sm: "auto" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: `font-weight-regular ${_vm.taskStatusColor}--text `,
                                              attrs: {
                                                "data-test":
                                                  "project-status-value",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.taskStatusText))]
                                          ),
                                          _vm.activeTaskStatus
                                            ? _c("v-progress-linear", {
                                                attrs: {
                                                  indeterminate: "",
                                                  height: "2",
                                                  color: _vm.taskStatusColor,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c("div", {
                                        staticStyle: { "min-width": "50px" },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.taskStatusMessageOnError
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mr-sm-2 mr-1 ml-3",
                                  attrs: { cols: "5", sm: "auto" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        align: "center",
                                        "align-content": "end",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          class:
                                            _vm.taskID !== undefined
                                              ? "ma-0 pa-0 mb-n2 mb-sm-0 mt-1 mt-sm-0"
                                              : "",
                                          attrs: {
                                            cols: "12",
                                            sm: "auto",
                                            "data-test": "task-id-label",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Error:" + _vm._s("\xa0") + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "ma-0 pa-0 flex-nowrap",
                                          attrs: { cols: "12", sm: "auto" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 flex-nowrap",
                                              attrs: { cols: "12", sm: "auto" },
                                            },
                                            [
                                              _c(
                                                "code",
                                                {
                                                  staticClass:
                                                    "font-weight-light my-3 align-center",
                                                  staticStyle: {
                                                    "font-size": "0.875em",
                                                    "min-width": "50px",
                                                    "min-height": "36px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.taskStatusMessageOnError
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.taskIsActive
                            ? _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-sm-3 ml-n1",
                                      attrs: {
                                        color: "secondary",
                                        elevation: "0",
                                        outlined: "",
                                        height: _vm.$vuetify.breakpoint.mobile
                                          ? "25px"
                                          : "20px",
                                        "x-small": "",
                                      },
                                      on: { click: _vm.sendCancelActiveTask },
                                    },
                                    [
                                      !_vm.$vuetify.breakpoint.mobile
                                        ? [_vm._v(" Cancel ")]
                                        : _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v(" mdi-cancel ")]
                                          ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }