import { defineStore } from "pinia";
import * as signaloidClient from "@/js/signaloidClient";
import axios from "axios";
import { isBuildActive, isBuildTerminated, Build, BuildStatus } from "@/types/api/builds";
import { useEventBus, UseEventBusReturn } from "@vueuse/core";
import * as Sentry from "@sentry/vue";

import type { EventBusKey } from "@vueuse/core";
import { RemoteAccessState, BuildBusEvent, BuildEvent, BuildEventE } from "@/types/general";
import { useUserStore } from "./user";

import * as util from "@/js/utilities";
import { UserLimitsE } from "@/js/tierconfig";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { useRootStore } from "@/stores/root";
import { monitoringCaptureError } from "@/plugins/monitoring";
import { TraceVariableWithTraceType } from "@/types/api/nodes";

type StoreState = {
	buildHistory: undefined | Build[];
	activeBuilds: undefined | Build[];
	buildHistoryUpdateStatus: RemoteAccessState;
	activeBuildUpdateStatus: RemoteAccessState;
	subscribedBuilds: BuildSubscription[];
	buildEventBus: UseEventBusReturn<BuildBusEvent, any>;
	buildListContinuationKey: undefined | string;
	activeBuildsListContinuationKey: undefined | string;
};

type BuildSubscription = { buildID: string; buildData: any; retryCount: number };

export const buildKey: EventBusKey<BuildBusEvent> = Symbol("build-symbol-key");

/*
 * given n = builds returned per request, the SCDP will try to look for
 * active builds in the first (n * activeBuildMaxFetchDepth) builds in the database
 * at the time of writing n = 50
 */
const activeBuildMaxFetchDepth = 3;

export const useBuildsStore = defineStore("builds", {
	state: (): StoreState => ({
		buildHistory: undefined,
		activeBuilds: [],
		buildHistoryUpdateStatus: { loading: false, error: false, message: "" },
		activeBuildUpdateStatus: { loading: false, error: false, message: "" },
		buildListContinuationKey: undefined,
		activeBuildsListContinuationKey: undefined,
		subscribedBuilds: [],
		buildEventBus: useEventBus(buildKey),
	}),
	getters: {
		getBuildByProjectID: (state) => (projectID: string) => {
			if (!state.buildHistory) {
				return undefined;
			}
			return state.buildHistory.find((x) => x.BuildID === projectID) ?? null;
		},
		getBuildByID: (state) => (buildID: string) => {
			if (!state.buildHistory || !buildID) {
				return undefined;
			}
			return state.buildHistory.find((x) => x.BuildID === buildID) ?? null;
		},
		inactiveBuilds(): Build[] {
			if (!this.buildHistory) {
				return [];
			}

			return this.buildHistory.filter((x) => !isBuildActive(x.Status));
		},
	},
	actions: {
		async getBuildByIdOrFetch(buildID: string): Promise<Build> {
			const localBuild = this.getBuildByID(buildID);
			if (localBuild) {
				return localBuild;
			}
			const response = await signaloidClient.getBuildByID(buildID);
			const buildFromRemote = response.data as Build;
			
			this.buildHistory = this.buildHistory 
				? [buildFromRemote, ...this.buildHistory]
				: [buildFromRemote];
			return buildFromRemote;
		},
		updateBuildByID(buildData: Build) {
			if (this.buildHistory) {
				this.buildHistory.splice(
					this.buildHistory.findIndex((x) => x.BuildID === buildData.BuildID),
					1,
					buildData
				);
				// //FIXME: This is wasteful
				// this.buildHistory.sort((a, b) => {
				// 	return b.CreatedAt - a.CreatedAt;
				// });
			}
			if (this.activeBuilds) {
				this.activeBuilds.splice(
					this.activeBuilds.findIndex((x) => x.BuildID === buildData.BuildID),
					1,
					buildData
				);
			}
		},
		removeFromActiveBuildList(buildID: string) {
			if (this.activeBuilds) {
				const activeBuildIndex = this.activeBuilds.findIndex((x) => x.BuildID === buildID);
				if (activeBuildIndex !== -1) {
					this.activeBuilds.splice(activeBuildIndex, 1);
				}
			}
		},
		addToActiveBuildList(buildData: Build) {
			this.activeBuilds?.push(buildData);
		},
		subscribeToActiveBuilds() {
			if (this.activeBuilds) {
				this.activeBuilds.forEach((build) => {
					const subscribedBuildIndex = this.subscribedBuilds.findIndex((x) => x.buildID === build.BuildID);
					if (subscribedBuildIndex == -1) {
						// No sub for this build
						this.subscribeToBuild(build.BuildID);
					}
				});
			}
		},
		async initialiseBuildHistory() {
			// Remove all builds from local storage
			localStorage.removeItem("build-list");
			this.buildListContinuationKey = undefined;
			this.activeBuildsListContinuationKey = undefined;
			await this.fetchNextBuildBatchFromRemote();
			// await this.fetchActiveBuildsFromRemote();
		},

		async fetchActiveBuildsFromRemote() {
			try {
				this.activeBuildUpdateStatus.loading = true;
				let requestCounter = 0;
				do {
					await this.fetchNextActiveBuildBatchFromRemote();
					requestCounter += 1;
				} while (
					requestCounter < activeBuildMaxFetchDepth &&
					this.activeBuildsListContinuationKey !== undefined
				);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status === 404) {
						// No builds found. set the history to be empty
						this.activeBuilds = [];
					}
				}
			} finally {
				this.activeBuildUpdateStatus.loading = false;
				this.subscribeToActiveBuilds();
			}
		},
		async fetchNextActiveBuildBatchFromRemote(triggeredByUser: boolean = false) {
			try {
				const response = await this.fetchBuildsFromRemote(this.activeBuildsListContinuationKey, [
					"Accepted",
					"Initialising",
					"In Progress",
				]);

				if (response.data.Builds) {
					this.activeBuilds =
						this.activeBuilds && this.buildListContinuationKey
							? this.activeBuilds.concat(response.data.Builds)
							: response.data.Builds;

					this.activeBuildsListContinuationKey = response.data.ContinuationKey;
				}
			} catch (error) {
				let isForbiddenError = false;

				if (axios.isAxiosError(error)) {
					if (error.response) {
						if (error.response.status === 404) {
							// No builds found. set the history to be empty
							this.activeBuilds = [];
						} else if (error.response.status === 403 || error.response.status === 402) {
							isForbiddenError = true;
						}
					}
				}
				if (!isForbiddenError) {
					monitoringCaptureError(error, "Get active builds from remote");
				}
			} finally {
				// this.activeBuildUpdateStatus.loading = false; // Uncomment if needed
			}
		},

		async fetchNextBuildBatchFromRemote(triggeredByUser: boolean = false) {
			try {
				this.buildHistoryUpdateStatus.loading = true;
				const response = await this.fetchBuildsFromRemote(this.buildListContinuationKey);
				if (response.data.Builds) {
					this.buildHistory =
						this.buildHistory && this.buildListContinuationKey
							? this.buildHistory.concat(response.data.Builds)
							: response.data.Builds;
					this.buildListContinuationKey = response.data.ContinuationKey;

					//FIXME: This is wasteful
					this.buildHistory.sort((a, b) => {
						return b.CreatedAt - a.CreatedAt;
					});
				}
			} catch (error) {
				let isForbiddenError = false;

				if (axios.isAxiosError(error)) {
					if (error.response) {
						if (error.response.status === 404) {
							// No builds found. set the history to be empty
							this.buildHistory = [];
						} else if (error.response.status === 403 || error.response.status === 402) {
							isForbiddenError = true;
						}
					}
				}
				if (!isForbiddenError) {
					monitoringCaptureError(error, "Get builds from remote");
				}
			} finally {
				this.buildHistoryUpdateStatus.loading = false;
			}
		},

		async fetchBuildsFromRemote(startKey?: string, buildStatus?: BuildStatus[]) {
			const authUserStore = useUserStore();
			await util.waitFor(() => authUserStore.currentUserSessionObject !== undefined);
			try {
				const response = await signaloidClient.getUserBuilds(startKey, buildStatus);
				return response;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchAndParseVariables(buildID: string) {
			let discoveredVariables: TraceVariableWithTraceType[] = [];
			try {
				const response = await signaloidClient.getBuildVariables(buildID);
				if (response.data.Variables) {
					discoveredVariables =
						response.data["Variables"].map((variableTrace): TraceVariableWithTraceType => {
							return {
								Expression: variableTrace.Name,
								File: variableTrace.File,
								LineNumber: variableTrace.Line,
								Type: variableTrace.Type,
							};
						}) ?? [];
				}
			} catch (error) {
				monitoringCaptureError(error, "Parse task variables");
			}
			return discoveredVariables;
		},
		async subscribeToBuild(buildID: string) {
			const subscribedBuildIndex = this.subscribedBuilds.findIndex((x) => x.buildID === buildID);
			if (subscribedBuildIndex != -1) {
				return;
			}
			// Add to subscribed build list
			this.subscribedBuilds.push({ buildID: buildID, buildData: null, retryCount: 0 });

			//Make the first call
			const firstCallStatus = await this.getBuildUpdate(buildID);

			// Limiter
			const maxErrorRetries: number = 3;
			const errorDelayIncreaseCoefficient: number = 2;

			const maxRetryDelayInMilliseconds: number = 6000;
			const delayIncreaseCoefficient: number = 1.1;
			const defaultRetryDelayInMilliseconds: number = 500;

			let lastStatus = firstCallStatus;
			let errorRetry: number = 0;
			let retryDelayInMilliseconds: number = defaultRetryDelayInMilliseconds;

			while (
				(lastStatus !== "Error" && !isBuildTerminated(lastStatus as BuildStatus)) ||
				errorRetry >= maxErrorRetries
			) {
				await new Promise((resolve) => setTimeout(resolve, retryDelayInMilliseconds));

				const callStatus = await this.getBuildUpdate(buildID);

				const isError = callStatus == "Error";
				const statusChanged = callStatus !== lastStatus;

				if (statusChanged) {
					//Reset retry delay to default
					retryDelayInMilliseconds = defaultRetryDelayInMilliseconds;
				} else {
					const coefficient = isError ? errorDelayIncreaseCoefficient : delayIncreaseCoefficient;
					const increasedDelay = retryDelayInMilliseconds * coefficient;
					retryDelayInMilliseconds = Math.min(increasedDelay, maxRetryDelayInMilliseconds);
				}
				// Update status for next iteration
				if (isError) {
					errorRetry += 1;
				}
				lastStatus = callStatus;
			}
		},
		async getBuildUpdate(buildID: string): Promise<string> {
			try {
				// get build data from API
				const response = await signaloidClient.getBuildByID(buildID);
				this.handleBuildUpdates(response.data);

				return response.data.Status;
			} catch (error) {
				Sentry.captureException(error);
				return "Error";
			}
		},
		handleBuildUpdates(buildData: Build) {
			// update the build object in build history
			this.updateBuildByID(buildData);
			const subscribedBuildIndex = this.subscribedBuilds.findIndex((x) => x.buildID === buildData.BuildID);

			// Check if build is terminated/completed
			if (isBuildTerminated(buildData.Status)) {
				this.subscribedBuilds.splice(subscribedBuildIndex, 1);
				this.removeFromActiveBuildList(buildData.BuildID);

				this.buildEventBus.emit({ type: BuildEventE.BuildCompleted, buildData: buildData });

				const rootStore = useRootStore();
				rootStore.tierLimitEventBus.emit({
					type: TierLimitEventTypeE.UsageChanged,
					affectedLimits: [
						UserLimitsE.RegistryStorageBytesMaximum,
						UserLimitsE.BuildTimeInMillisecondsCount,
						UserLimitsE.ConcurrentBuildCount,
					],
				});
				return;
			}
			// Update status of ongoing builds
			this.buildEventBus.emit({ type: BuildEventE.BuildUpdated, buildData: buildData });
		},
	},
});
