
import { defineComponent, ref } from "vue";

// Types
import { TraceVariable, TraceVariableWithTraceType } from "@/types/api/nodes";
import { BuildBusEvent, RemoteAccessState, ResultsPanelTab, TaskBusEvent } from "@/types/general";
import { UserLimitsE } from "@/js/tierconfig";
import { TaskStatus, Task, isTaskTerminated, isTaskFail, TaskStatusE } from "@/types/api/tasks";

// Libraries
const { createHash } = require("crypto");
import axios from "axios";

// Utilities
import * as Sentry from "@sentry/vue";
import * as signaloidClient from "@/js/signaloidClient";
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import Editor from "@/components/Editor.vue";
import Panel from "@/components/Panel.vue";
import VariableViewer from "@/components/DevelopmentPlatform/VariableViewer.vue";

// Stores
import { useTasksStore } from "@/stores/tasks";
import { useEditorStore } from "@/stores/editor";
import { useRootStore } from "@/stores/root";

// Global variables
import { defaultTabs } from "@/assets/defaultTabs";
import { useCoresStore } from "@/stores/cores";
import { useUserStore } from "@/stores/user";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { useOutputsStore } from "@/stores/outputs";
import OutputFailedDialog from "@/components/Common/OutputFailedDialog.vue";
import {
	Build,
	BuildStatus,
	BuildStatusE,
	canFetchVariables,
	isBuildFail,
	isBuildTerminated,
} from "@/types/api/builds";
import { SourceCodeLanguages } from "@/types/api/sourceCode";
import { useBuildsStore } from "@/stores/builds";

// Local Types
type ViewportAreaSize = { height: string; width: string };
type ViewportArea = { [key: string]: ViewportAreaSize };
type ViewportArrangement = { [key: string]: ViewportArea };

type ComponentData = {
	taskID: undefined | string;
	taskStatus: undefined | TaskStatus;
	activeTask: undefined | Task;
	buildID: undefined | string;
	buildStatus: undefined | BuildStatus;
	activeBuild: undefined | Partial<Build>;
	results: undefined | ResultsPanelTab[];
	resultsLoading: boolean;
	viewportRatio: ViewportArrangement;
	discoveredVariables: TraceVariableWithTraceType[];
	customVariableExpressions: TraceVariableWithTraceType[];
	variableDiscoveryInProgress: boolean;
	codeHash: undefined | string;
	taskAccessState: RemoteAccessState;
	buildAccessState: RemoteAccessState;
	taskEventBusSub: undefined | Function;
	buildEventBusSub: undefined | Function;
	lastBuildForVariableDiscovery: boolean;
	showTaskFailedDialog: boolean;
	showBuildFailedDialog: boolean;
};

export default defineComponent({
	name: "DevelopmentPlatform",
	components: { Editor, Panel, VariableViewer, OutputFailedDialog },
	data: (): ComponentData => ({
		taskID: undefined,
		taskStatus: undefined,
		activeTask: undefined,
		buildID: undefined,
		buildStatus: undefined,
		activeBuild: undefined,
		resultsLoading: false,
		results: undefined,
		viewportRatio: {
			horizontal: {
				editorPanel: { height: "75vh", width: "8" },
				resultsPanel: { height: "75vh", width: "4" },
				variableViewer: { height: "75.2vh", width: "3" },
			},
			vertical: {
				editorPanel: { height: "50vh", width: "12" },
				resultsPanel: { height: "20vh", width: "12" },
				variableViewer: { height: "50vh", width: "4" },
			},
			stacked: {
				editorPanel: { height: "45vh", width: "12" },
				resultsPanel: { height: "20vh", width: "12" },
				variableViewer: { height: "20vh", width: "12" },
			},
		},
		discoveredVariables: [],
		customVariableExpressions: [],
		variableDiscoveryInProgress: false,
		codeHash: undefined,
		taskAccessState: {
			loading: false,
			status: undefined,
			error: false,
			message: "",
		},
		buildAccessState: {
			loading: false,
			status: undefined,
			error: false,
			message: "",
		},
		taskEventBusSub: undefined,
		buildEventBusSub: undefined,
		lastBuildForVariableDiscovery: false,
		showTaskFailedDialog: false,
		showBuildFailedDialog: false,
	}),
	setup() {
		const tasksStore = useTasksStore();
		const buildsStore = useBuildsStore();
		const editorStore = useEditorStore();
		const rootStore = useRootStore();
		const coresStore = useCoresStore();
		const userStore = useUserStore();
		const outputsStore = useOutputsStore();
		const editor = ref<InstanceType<typeof Editor>>();
		const variableViewer = ref<InstanceType<typeof VariableViewer>>();

		return {
			tasksStore,
			buildsStore,
			editorStore,
			rootStore,
			coresStore,
			userStore,
			editor,
			variableViewer,
			util,
			outputsStore,
		};
	},

	computed: {
		// activeTask(): undefined | Task {
		// 	// FIXME: get task data from the store if taskID is defined.
		// 	if (this.taskID) {
		// 		return this.tasksStore.getTaskByID(this.taskID) ?? undefined;
		// 	} else {
		// 		return undefined;
		// 	}
		// },
		resultsPanelText(): readonly ResultsPanelTab[] {
			return this.results ?? defaultTabs;
		},

		computedEditorLayout: function (): string {
			if (this.$vuetify.breakpoint.xs) {
				return "stacked";
			} else if (this.$vuetify.breakpoint.sm) {
				if (this.showVariableViewer) {
					return "stacked";
				} else {
					return "vertical";
				}
			} else if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {
				if (this.showVariableViewer) {
					return "vertical";
				} else {
					return this.editorStore.editorLayout;
				}
			} else {
				return this.editorStore.editorLayout;
			}
		},
		completeVariablesList(): TraceVariableWithTraceType[] {
			return [...this.discoveredVariables, ...this.customVariableExpressions];
		},
		showVariableViewer(): boolean {
			return this.editorStore.variableViewerVisibility ?? false;
		},
	},
	methods: {
		async submitBuildRequest() {
			this.buildAccessState = {
				loading: false,
				status: undefined,
				error: false,
				message: "",
			};
			this.taskAccessState = {
				loading: false,
				status: undefined,
				error: false,
				message: "",
			};
			this.taskStatus = undefined;
			this.buildStatus = undefined;
			this.activeTask = undefined;
			this.activeBuild = undefined;
			this.buildID = undefined;
			this.taskID = undefined;

			this.buildStatus = BuildStatusE.Accepted;
			try {
				const sourceCode = this.editor?.getCode();
				const language = this.editor?.getSelectedLanguage();
				const activeCore = this.editor?.getActiveCore();
				const variableTraceList = this.variableViewer?.getTraceVariables();

				const userPrimaryOrganization = await this.userStore.getCurrentUserPrimaryOrganization();

				const response = await signaloidClient.startSourceCodeBuild(
					{
						Code: sourceCode ?? "",
						Language: (language?.name as SourceCodeLanguages) || "C",
						CoreID: activeCore?.CoreID,
						TraceVariables: variableTraceList,
					},
					userPrimaryOrganization ?? undefined
				);
				this.lastBuildForVariableDiscovery = false;

				const buildResponse = await signaloidClient.getBuildByID(response.data.BuildID);

				if (response.status == 202) {
					this.buildID = response.data.BuildID;
					this.activeBuild = buildResponse.data;
					this.buildStatus = BuildStatusE.Accepted;

					if (!this.buildID) {
						throw new Error("Build ID not returned from API");
					}

					console.log(`Build created ID: ${this.activeBuild?.BuildID}`);
					// @ts-ignore
					this.$posthog?.capture("build_created", {
						buildType: "SourceCode",
						buildID: this.activeBuild?.BuildID,
					});

					this.buildsStore.addToActiveBuildList(buildResponse.data);
					this.buildsStore.subscribeToBuild(response.data.BuildID);
				} else {
					throw new Error("Unknown error: failed to create build on server");
				}
			} catch (error) {
				this.variableDiscoveryInProgress = false;

				if (axios.isAxiosError(error)) {
					if (error.response) {
						console.warn("Non 2xx response:", error.response);

						let severityLevel: Sentry.SeverityLevel = "error";

						if (error.response.status === 504) {
							this.buildStatus = BuildStatusE.Error;
							this.buildAccessState.message =
								"The build request timed out." +
								" Please try starting the build again. If this error persists," +
								" please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 502 || error.response.status === 500) {
							this.buildStatus = BuildStatusE.Error;
							this.buildAccessState.message =
								"Our system encountered an internal error while starting the build." +
								" Our team will receive a high-priority notification for this issue." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
							severityLevel = "fatal";
						} else if (error.response.status === 503) {
							this.buildStatus = BuildStatusE.Error;
							this.buildAccessState.message =
								"Our systems are currently experiencing an unusually large number of requests and cannot accept new builds at the moment." +
								" We are hard at work scaling up our infrastructure. Please try again later." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status >= 500) {
							this.buildStatus = BuildStatusE.Error;
							this.buildAccessState.message =
								`Our system encountered a server error while starting the build (HTTP Code ${error.response.status}).` +
								" Please try starting the build again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 401) {
							this.buildStatus = BuildStatusE.Error;
							this.buildAccessState.message =
								"Our system encountered an authorization error while starting the build." +
								" Signing out and back in will ensure you are properly authorized." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 403 || error.response.status === 402) {
							this.buildStatus = BuildStatusE.TierLimited;

							if (error.response.data.message.toLowerCase().includes("dynamic instruction")) {
								this.buildAccessState.message =
									"You have reached the monthly computation limit for your account." +
									" Please upgrade your account in https://signaloid.io/billing to run more builds." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.DynamicInstructionCount],
								});
							} else {
								this.buildAccessState.message =
									"You have reached the limit of available concurrently-running builds for your account." +
									" Please upgrade your account in https://signaloid.io/billing to enable builds running concurrently." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.ConcurrentBuildCount],
								});
							}
						} else if (error.response.status >= 400) {
							this.buildStatus = BuildStatusE.Error;
							this.buildAccessState.message =
								`Our system encountered an error while starting the new build (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the build again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else {
							this.buildStatus = BuildStatusE.Error;
							this.buildAccessState.message =
								`Our system encountered an error while starting the new build (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the build again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						}

						this.buildAccessState.error = true;

						Sentry.captureException(new Error("Build rejected: Non 2xx response"), {
							level: severityLevel,
							extra: { error: error, userMessage: this.buildAccessState.message },
							tags: { service: "create-build" },
						});
					} else if (error.request) {
						this.buildStatus = BuildStatusE.Error;
						this.buildAccessState.error = true;
						this.buildAccessState.message =
							"A build request was initiated but there was no response from our servers." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Build failed: Request Failed"), {
							extra: { error: error, userMessage: this.buildAccessState.message },
							tags: { service: "create-build" },
						});
					} else {
						this.buildStatus = BuildStatusE.Error;
						this.buildAccessState.error = true;
						this.buildAccessState.message =
							"A browser error occurred when trying to create the build request." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Build failed: Preflight error"), {
							extra: { error: error, userMessage: this.buildAccessState.message },
							tags: { service: "create-build" },
						});
					}
				} else {
					this.buildStatus = BuildStatusE.Error;
					this.buildAccessState.error = true;
					this.buildAccessState.message =
						"The build request could not be completed due to an unexpected error." +
						" If this error persists, please contact support at developer-support@signaloid.com.";
					Sentry.captureException(new Error("Build failed: Unknown error"), {
						extra: { error: error, userMessage: this.buildAccessState.message },
						tags: { service: "create-build" },
					});
				}
			}
		},

		async submitTaskRequest() {
			if (!this.buildID) {
				return;
			}

			this.taskID = undefined;
			try {
				const runArguments = this.editor?.getRunArguments();
				const dataSources = await this.editor?.getDataSources();

				const userPrimaryOrganization = await this.userStore.getCurrentUserPrimaryOrganization();
				const response = await signaloidClient.startBuildTask(
					this.buildID,
					{
						Arguments: runArguments,
						DataSources: dataSources ?? [],
					},
					userPrimaryOrganization ?? undefined
				);

				const taskResponse = await signaloidClient.getTaskByID(response.data.TaskID);

				// If the API accepts the task it will respond with "202:Accepted" Code
				if (response.status == 202) {
					this.activeTask = taskResponse.data;
					this.taskID = response.data.TaskID;
					this.taskStatus = taskResponse.data.Status;

					if (!this.taskID) {
						throw new Error("Task ID not returned from API");
					}

					console.log(`Task created ID: ${this.activeTask.TaskID}`);

					// @ts-ignore
					this.$posthog?.capture("task_created", {
						buildId: this.activeTask?.BuildID,
						taskId: this.activeTask?.TaskID,
					});

					this.tasksStore.addToActiveTaskList(this.activeTask);
					this.tasksStore.subscribeToTaskV2(this.taskID);
				} else {
					// TODO: handle error
					throw new Error("Unknown error: failed to create task to server");
				}
				/*
				 *	At this point the task has been accepted and will be queued to be run.
				 *	Any subsequent tasks will return with 403 if the concurrent task limit has been reached.
				 *	The task status updates will be handled by `taskEventBusCallback`
				 */
			} catch (error) {
				// this.buildAndRunLoading = false;
				this.variableDiscoveryInProgress = false;

				if (axios.isAxiosError(error)) {
					if (error.response) {
						/*
						 *	The client received a response with HTTP Code != 2xx
						 */
						console.warn("Non 2xx response:", error.response);

						let severityLevel: Sentry.SeverityLevel = "error";

						if (error.response.status === 504) {
							this.taskStatus = TaskStatusE.Error;
							this.taskAccessState.message =
								" The task request timed out." +
								" Please try starting the task again. If this error persists," +
								" please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 502 || error.response.status === 500) {
							/*
							 *	A 502 error 99.9% means that a Lambda is failing (the way AWS is currently set up)
							 */
							this.taskStatus = TaskStatusE.Error;
							this.taskAccessState.message =
								"Our system encountered an internal error while starting the task." +
								" Our team will receive a high priority notification for this issue." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
							severityLevel = "fatal"; // This is fatal because it usually means that a Lambda is crashing.
						} else if (error.response.status === 503) {
							this.taskStatus = TaskStatusE.Error;
							this.taskAccessState.message =
								"Our systems are currently experiencing an unusually large number of requests and cannot accept new tasks at the moment." +
								" We are hard at work scaling up our infrastructure. Please try again later." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status >= 500) {
							/*
							 *	Other 5xx response
							 */
							this.taskStatus = TaskStatusE.Error;
							this.taskAccessState.message =
								`Our system encountered a server error while starting the task (HTTP Code ${error.response.status}).` +
								" Please try starting the task again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 401) {
							/*
							 *	Axios interceptors should deal with this and
							 *	silently re-authenticate the user. If that
							 *	fails, we end up here.
							 */
							this.taskStatus = TaskStatusE.Error;
							this.taskAccessState.message =
								"Our system encountered an authorization error while starting the task." +
								" Signing out and back in will ensure you are properly authorized." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (error.response.status === 403 || error.response.status === 402) {
							this.taskStatus = TaskStatusE.TierLimited;

							if (error.response.data.message.toLowerCase().includes("dynamic instruction")) {
								this.taskAccessState.message =
									"You have reached the monthly computation limit for your account." +
									" Please upgrade your account in https://signaloid.io/billing to run more tasks." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.DynamicInstructionCount],
								});
							} else {
								this.taskAccessState.message =
									"You have reached the limit of available concurrently-running tasks for your account." +
									" Please upgrade your account in https://signaloid.io/billing to enable tasks running concurrently." +
									" If you have further questions, please contact support at developer-support@signaloid.com.";
								this.rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.ConcurrentTaskCount],
								});
							}
						} else if (error.response.status >= 400) {
							/*
							 *	Other 4xx response
							 */
							this.taskStatus = TaskStatusE.Error;
							this.taskAccessState.message =
								`Our system encountered an error while starting the new task (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the task again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else {
							/*
							 *	Other HTTP errors
							 */
							this.taskStatus = TaskStatusE.Error;
							this.taskAccessState.message =
								`Our system encountered an error while starting the new task (HTTP Code ${error.response.status} ${error.response.statusText}).` +
								" Please try starting the task again." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						}

						this.taskAccessState.error = true;

						Sentry.captureException(new Error("Task rejected: Non 2xx response"), {
							level: severityLevel,
							extra: { error: error, userMessage: this.taskAccessState.message },
							tags: { service: "create-task" },
						});
					} else if (error.request) {
						//no response
						this.taskStatus = TaskStatusE.Error;
						this.taskAccessState.error = true;
						this.taskAccessState.message =
							"A task request initiated but there was no response from our servers." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Task failed: Request Failed"), {
							extra: { error: error, userMessage: this.taskAccessState.message },
							tags: { service: "create-task" },
						});
					} else {
						//making request failed
						this.taskStatus = TaskStatusE.Error;
						this.taskAccessState.error = true;
						this.taskAccessState.message =
							"A browser error occurred when trying to create the task request." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
						Sentry.captureException(new Error("Task failed: Preflight error"), {
							extra: { error: error, userMessage: this.taskAccessState.message },
							tags: { service: "create-task" },
						});
					}
				} else {
					this.taskStatus = TaskStatusE.Error;
					this.taskAccessState.error = true;
					this.taskAccessState.message =
						"The task request could not be completed due to an unexpected error." +
						" If this error persists, please contact support at developer-support@signaloid.com.";
					Sentry.captureException(new Error("Task failed: Unknown error"), {
						extra: { error: error, userMessage: this.taskAccessState.message },
						tags: { service: "create-task" },
					});
				}
			}
		},
		async cancelActiveTask() {
			if (this.taskID) {
				try {
					await signaloidClient.cancelTaskByID(this.taskID);
				} catch (error) {
					monitoringCaptureError(error, "Cancel active task");
				}
			}
		},
		async cancelActiveBuild() {
			if (this.buildID) {
				try {
					await signaloidClient.cancelBuildByID(this.buildID);
				} catch (error) {
					monitoringCaptureError(error, "Cancel active build");
				}
			}
		},
		async discoverVariables() {
			// FIXME: use post project end point to build and run on the default core and get the variable list
			// TODO: Update this to use Modules endpoint or tasks with build only query param.

			this.variableDiscoveryInProgress = true;
			try {
				const sourceCode = this.editor?.getCode();
				const language = this.editor?.getSelectedLanguage();

				const userPrimaryOrganization = await this.userStore.getCurrentUserPrimaryOrganization();

				const response = await signaloidClient.startSourceCodeDiscoverVariablesBuild(
					{
						Code: sourceCode ?? "",
						Language: (language?.name as SourceCodeLanguages) || "C",
						CoreID: this.coresStore.getSmallestDefaultCore.CoreID,
					},
					userPrimaryOrganization ?? undefined
				);
				this.lastBuildForVariableDiscovery = true;

				const buildResponse = await signaloidClient.getBuildByID(response.data.BuildID);

				if (response.status == 202) {
					this.activeBuild = buildResponse.data;
					this.buildID = response.data.BuildID;
					this.buildStatus = buildResponse.data.Status;
					if (!this.buildID) {
						throw new Error("Build ID not returned from API");
					}

					console.log(`Build created ID: ${this.activeBuild?.BuildID}`);
					// @ts-ignore
					this.$posthog?.capture("build_created", {
						buildType: "SourceCode",
						buildID: this.activeBuild?.BuildID,
					});

					if (this.buildEventBusSub == undefined) {
						// Is this necessary?
						this.subscribeToBuildEventBus();
					}

					this.buildsStore.addToActiveBuildList(buildResponse.data);
					this.buildsStore.subscribeToBuild(this.buildID);
				} else {
					throw new Error("Unknown error: failed to create build on server");
				}
			} catch (error) {
				monitoringCaptureError(error, "Discover variables");
			}
		},
		switchToReferenceCore() {
			this.editor?.switchToReferenceCore();
		},
		referenceCoreChosen() {
			try {
				const activeCore = this.editor?.getActiveCore();
				return activeCore?.Microarchitecture == "Reference";
			} catch (error) {
				return false;
			}
		},
		// // TODO:
		// dragStart(event, a) {
		// 	event.dataTransfer.dropEffect = "move";
		// 	event.dataTransfer.effectAllowed = "move";
		// 	event.preventDefault();
		// 	const target = event.target;
		// 	console.log("event", event);
		// },
		// // TODO:
		// onDropHandle(event, item) {
		// 	const target = event.target;
		// 	console.log("event", event);
		// },
		// getLayoutDimensions(panelName) {
		// 	if (this.showVariableViewer) {
		// 	}
		// 	this.viewportRatio[this.computedEditorLayout][panelName];
		// },
		calculateCodeHash() {
			try {
				const code = this.editor?.getCode();
				this.codeHash = createHash("sha256").update(code).digest("hex");
			} catch (error) {
				monitoringCaptureError(error, "Calculate code editor source hash");
			}
		},
		addCustomTraceExpression(variableTraceToAdd: TraceVariableWithTraceType) {
			this.customVariableExpressions.push(variableTraceToAdd);
		},
		removeCustomTraceExpression(variableTraceToRemove: TraceVariableWithTraceType) {
			const traceVariableIndex = this.customVariableExpressions.findIndex((v) => {
				return (
					v.Expression == variableTraceToRemove.Expression &&
					v.LineNumber == variableTraceToRemove.LineNumber &&
					v.File == variableTraceToRemove.File
				);
			});
			this.customVariableExpressions.splice(traceVariableIndex, 1);
		},

		async buildEventBusCallback(event: BuildBusEvent) {
			// @ts-ignore FIXME:
			if (this.buildID && event.buildData.BuildID === this.buildID) {
				this.activeBuild = event.buildData;
				this.buildStatus = event.buildData.Status;
				if (this.lastBuildForVariableDiscovery && this.buildStatus && canFetchVariables(this.buildStatus)) {
					try {
						// fetch task variables
						this.buildsStore.fetchAndParseVariables(this.buildID).then((result) => {
							this.discoveredVariables = result;
							this.variableDiscoveryInProgress = false;
						});
					} catch (error) {
						monitoringCaptureError(error, "Handle active source task status change");
					}
				}

				const normalBuildExecution = this.buildStatus == "Completed" && !this.lastBuildForVariableDiscovery;
				const variableDiscoveryFailed = isBuildFail(this.buildStatus) && this.lastBuildForVariableDiscovery;
				const buildFail = isBuildFail(this.buildStatus) && !this.lastBuildForVariableDiscovery;
				if (normalBuildExecution) {
					// Optimistic Status Update
					this.taskStatus = TaskStatusE.Accepted;
					this.submitTaskRequest();
				}

				if (variableDiscoveryFailed || buildFail) {
					try {
						await this.outputsStore.loadBuildOutputs(this.buildID);
					} catch (error) {
						this.showTaskFailedDialog = true;
						monitoringCaptureError(error, "Handle terminated source build status change");
						throw error;
					}
				}
			}
		},
		async taskEventBusCallback(event: TaskBusEvent) {
			// console.log("taskEventBusSub event (in launch tab): ", event);
			// @ts-ignore FIXME:
			if (this.taskID && event.taskData.TaskID === this.taskID) {
				// console.log("update for this task :>> status = ", event.taskData.Status);
				this.activeTask = event.taskData;
				this.taskStatus = event.taskData.Status;

				try {
					if (isTaskTerminated(this.taskStatus)) {
						try {
							await this.outputsStore.loadTaskOutputs(this.taskID);
						} catch (error) {
							this.showTaskFailedDialog = true;
							throw error;
						}
					}
				} catch (error) {
					monitoringCaptureError(error, "Handle terminated source task status change");
				}
			}
		},
		subscribeToBuildEventBus() {
			this.buildEventBusSub = this.buildsStore.buildEventBus.on(this.buildEventBusCallback);
		},
		subscribeToTaskEventBus() {
			this.taskEventBusSub = this.tasksStore.taskEventBus.on(this.taskEventBusCallback);
		},
	},
	mounted() {
		// Create a subscription to the task/build event bus
		this.subscribeToTaskEventBus();
		this.subscribeToBuildEventBus();

		this.buildID = this.editorStore.getLastBuild;

		// if (this.$route.query.pid != undefined && typeof this.$route.query.pid == "string") {
		// 	this.projectID = this.$route.query.pid;
		// }

		this.calculateCodeHash();
	},
	beforeDestroy() {
		if (this.buildEventBusSub !== undefined) {
			this.buildEventBusSub();
		}
		// Unsubscribe from the task even bus on dismount
		if (this.taskEventBusSub !== undefined) {
			this.taskEventBusSub();
		}
	},
});
