
import { PropType, defineComponent } from "vue";

// Types
import { TaskStatusE, Task, isTaskActive, isTaskFail } from "@/types/api/tasks";

// Libraries
import moment from "moment";

// Utilities
import * as signaloidClient from "@/js/signaloidClient";
import { repoFullNameFromUrl } from "@/js/utilities";
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
import ActiveCoreTooltip from "@/components/Repositories/ActiveCoreTooltip.vue";
import TaskStatusTransitionTooltip from "@/components/TaskManager/TaskStatusTransitionTooltip.vue";
import TasksRemoveDialog from "@/components/TaskManager/TaskRemoveDialog.vue";

// Stores
import { useTasksStore } from "@/stores/tasks";
import { useRootStore } from "@/stores/root";
import { Core, CoreSpecs } from "@/types/api/cores";
import { Build } from "@/types/api/builds";
import { useBuildsStore } from "@/stores/builds";
import { Repository } from "@/types/api/repositories";
import { SourceCode } from "@/types/api/sourceCode";
import { useCoresStore } from "@/stores/cores";

// Global variables
const AsTimeStamp_19900101 = 631152000;

type ComponentData = {
	remoteError: boolean;
	remoteErrorMessage: string | undefined;
	removeTaskDialog: boolean;
	buildData: Build | undefined;
};

export default defineComponent({
	name: "TaskCard",
	components: {
		TooltipButton,
		CopyToClipboardButton,
		ActiveCoreTooltip,
		TaskStatusTransitionTooltip,
		TasksRemoveDialog,
	},
	props: {
		taskID: { type: String, required: true },
		taskData: { type: Object as PropType<Task>, required: true },
		// buildData: { type: Object as PropType<Build>, required: false },
		showDeleteTaskButton: { type: Boolean, default: false },
	},
	data: (): ComponentData => ({
		remoteError: false,
		remoteErrorMessage: undefined,
		removeTaskDialog: false,
		buildData: undefined,
	}),
	setup() {
		const tasksStore = useTasksStore();
		const buildsStore = useBuildsStore();
		const coresStore = useCoresStore();
		const rootStore = useRootStore();

		return {
			tasksStore,
			buildsStore,
			coresStore,
			rootStore,
			TaskStatusE,
			repoFullNameFromUrl,
			isTaskActive,
			util,
		};
	},
	methods: {
		async cancelActiveTask() {
			if (this.taskData?.TaskID) {
				try {
					await signaloidClient.cancelTaskByID(this.taskData.TaskID);
				} catch (error) {
					monitoringCaptureError(error, "Cancel active task");
				}
			}
		},
		normalizeTimestamp(timestamp: number): number {
			return timestamp > 2000000000 ? timestamp : timestamp * 1000;
		},

		secondsSinceEpochToDateString(timestamp: number) {
			//moment requires timestamp in milliseconds
			return moment(this.normalizeTimestamp(timestamp)).calendar(null, {
				sameDay: "[Today]",
				nextDay: "[Tomorrow]",
				nextWeek: "dddd",
				lastDay: "[Yesterday]",
				lastWeek: "[Last] dddd",
				sameElse: "DD/MM/YYYY",
			});
		},
		openTaskDetails() {
			//@ts-ignore
			this.$posthog?.capture("show_task_details_button_clicked");

			// @ts-ignore FIXME: For some reason TS does not like this redirect
			this.$router.push({
				name: "TaskDetail",
				params: { id: this.taskData?.TaskID, initialTaskData: this.taskData.TaskID },
			});
		},
		openRemoveTaskDialog() {
			this.removeTaskDialog = true;
		},
		closeRemoveTaskDialog() {
			this.removeTaskDialog = false;
		},
		removeTaskSuccess() {
			//@ts-ignore
			this.$posthog?.capture("task_metadata_deleted");
			this.$emit("removeTaskSuccess");
		},
	},
	computed: {
		taskBuild(): Build | null {
			const buildID = this.taskData?.BuildID;
			if (!buildID) return null;
			return this.buildsStore.getBuildByID(buildID) ?? null;
		},
		cardSourceCode(): SourceCode | null {
			const build = this.buildData;
			if (!build) return null;
			if (build.Application.Type == "SourceCode") {
				return build.Application.SourceCode as SourceCode;
			}
			return null;
		},
		cardRepo(): Repository | null {
			const build = this.buildData;
			if (!build) return null;
			if (build.Application.Type == "Repository") {
				return build.Application.Repository as Repository;
			}
			return null;
		},
		taskStatusMessageOnError: function (): string | null {
			if (this.taskData && !isTaskFail(this.taskData.Status)) {
				return null;
			}
			const statusTransition =
				this.taskData && this.taskData.StatusTransitions.filter((st) => st.Status == this.taskData?.Status)[0];

			// Kea-cloud errors are formatted as `ErrXXX: Error message.`, where `ErrXXX` is an enum-like identifier.
			// This code checks if the message contains a colon, splits it, and returns the error message without the prefix.
			if (statusTransition && statusTransition.Message?.includes(":")) {
				const messageParts = statusTransition.Message.split(":");
				return messageParts.slice(1).join(":").trim() || null;
			}
			return statusTransition?.Message ?? null;
		},
		runDuration(): string {
			const secondsOnly = true;
			const AsTimeStamp_19900101 = 631152000;

			if (!this.taskData || !this.taskData?.StartedAt || !this.taskData?.FinishedAt) {
				return "---";
			}
			const startedAtSeconds =
				this.taskData.StartedAt < 1e11 ? this.taskData.StartedAt : Math.floor(this.taskData.StartedAt / 1000);
			const finishedAtSeconds =
				this.taskData.FinishedAt < 1e11
					? this.taskData.FinishedAt
					: Math.floor(this.taskData.FinishedAt / 1000);

			if (startedAtSeconds <= AsTimeStamp_19900101 || finishedAtSeconds <= AsTimeStamp_19900101) {
				return "---";
			}

			const finishTime = moment(util.convertToMilliseconds(this.taskData.FinishedAt));
			const createTime = moment(util.convertToMilliseconds(this.taskData.CreatedAt));
			const startTime = moment(util.convertToMilliseconds(this.taskData.StartedAt));

			if (finishTime.isValid() && startTime.isValid()) {
				const duration = finishTime.diff(startTime);
				if (duration < 1000) {
					// less than a second
					return secondsOnly ? "< 1s" : `${duration}ms`;
				} else if (duration < 60000) {
					// less than a minute
					return `${Math.floor(duration / 1000)}s` + (secondsOnly ? "" : ` ${duration % 1000}ms`);
				} else {
					// minutes and seconds
					return `${Math.floor(duration / 60000)}m ${Math.floor((duration % 60000) / 1000)}s`;
				}
			} else {
				return "---";
			}
		},
		executionTimeString: function (): string {
			if (!this.taskData || !this.taskData?.Stats || !this.taskData?.Stats.ProcessorTime) {
				return "";
			}

			return util.usDurationToString(this.taskData?.Stats.ProcessorTime * 1000_000);
		},
		dynamicInstructions: function (): string {
			if (!this.taskData || !this.taskData?.Stats || !this.taskData?.Stats.DynamicInstructions) {
				return "";
			}

			return util.toStringWithMagnitude(this.taskData.Stats.DynamicInstructions);
		},
		startTimestamp: function (): string {
			if (!this.taskData?.StartedAt || this.taskData.StartedAt <= AsTimeStamp_19900101) {
				return "---";
			}
			return `${this.secondsSinceEpochToDateString(this.taskData.StartedAt)}, ${moment(
				this.normalizeTimestamp(this.taskData.StartedAt)
			).format("HH:mm:ss")}`;
		},
		createdTimestamp: function (): string {
			if (!this.taskData?.CreatedAt || this.taskData.CreatedAt <= AsTimeStamp_19900101) {
				return "---";
			}
			return `${this.secondsSinceEpochToDateString(this.taskData.CreatedAt)}, ${moment(
				this.normalizeTimestamp(this.taskData.CreatedAt)
			).format("HH:mm:ss")}`;
		},
		endTimestamp: function (): string {
			if (!this.taskData) {
				return "---";
			}
			if (
				moment(this.normalizeTimestamp(this.taskData.StartedAt)).isSame(
					this.normalizeTimestamp(this.taskData.FinishedAt),
					"day"
				)
			) {
				return moment(this.normalizeTimestamp(this.taskData.FinishedAt)).format("HH:mm:ss");
			} else {
				return `${this.secondsSinceEpochToDateString(this.taskData.FinishedAt)}, ${moment(
					this.normalizeTimestamp(this.taskData.FinishedAt)
				).format("HH:mm:ss A")}`;
			}
		},
		projectSourceIcon: function (): string {
			return util.projectSourceIcon(this.buildData?.Application.Type ?? "");
		},
		inTaskDetail(): boolean {
			return this.$route.name == "TaskDetail";
		},
		taskCoreName(): string {
			const buildID = this.taskData?.BuildID;
			if (!buildID) {
				return "Core"
			}
			const build = this.buildsStore.getBuildByID(buildID);
			const coreSpecs = build?.BuildCoreSpecs;
			if (!coreSpecs){
				return "Core"
			}
			const defaultCores = this.coresStore.allDefaultCores
			const chosenCore = defaultCores.find(dc => {
				const sameClass = dc.Class === coreSpecs.Class;
				const samePrecision = dc.Precision === coreSpecs.Precision;
				const sameMemory = dc.MemorySize === coreSpecs.MemorySize;
				const sameMicroarch = dc.Microarchitecture === coreSpecs.Microarchitecture;
				const sameCorrelation = dc.CorrelationTracking === coreSpecs.CorrelationTracking;
				
				return sameClass && samePrecision && sameMemory && sameMicroarch && sameCorrelation;
			});
			if (!chosenCore){
				return "Custom Core"
			}
			return chosenCore?.Name
		},
		taskCoreSpec(): CoreSpecs | undefined {
			const buildID = this.taskData?.BuildID;
			if (!buildID) {
				return;
			}
			const build = this.buildsStore.getBuildByID(buildID);
			const coreSpecs = build?.BuildCoreSpecs;
			return coreSpecs as CoreSpecs
		},
		taskArguments(): string | undefined {
			return this.taskData.Arguments;
		},
	},
	async beforeMount() {
		const buildID = this.taskData.BuildID;
		if (!buildID) return;
		const bd = await this.buildsStore.getBuildByIdOrFetch(buildID);
		if (!bd) return;
		this.buildData = bd;
	},
});
