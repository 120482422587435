
import { defineComponent, PropType } from "vue";
// Components
import TaskIDDisplay from "@/components/TaskIDDisplay.vue";
import BuildIDDisplay from "@/components/BuildIDDisplay.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";

// Types
import { isTaskActive, isTaskFail, Task, TaskStatus, TaskStatusE } from "@/types/api/tasks";
import { RemoteAccessState } from "@/types/general";
import { UserLimitsE } from "@/js/tierconfig";

// Utilities
import * as util from "@/js/utilities";
import { Build, BuildStatus, isBuildActive, isBuildFail } from "@/types/api/builds";

export default defineComponent({
	name: "EditorControlBar",
	components: {
		TaskIDDisplay,
		BuildIDDisplay,
		LimitableActionButton,
	},
	props: {
		taskStatus: { type: String as PropType<TaskStatus> },
		taskID: { type: String },
		taskAccessState: { type: Object as PropType<RemoteAccessState> },
		activeTask: { type: Object as PropType<undefined | Partial<Task>> },
		buildID: { type: String },
		buildStatus: { type: String as PropType<BuildStatus> },
		activeBuild: { type: Object as PropType<undefined | Partial<Build>> },
		buildAccessState: { type: Object as PropType<RemoteAccessState> },
	},
	setup() {
		return { isTaskActive, TaskStatusE, UserLimitsE };
	},
	computed: {
		taskStatusMessageOnError: function (): string | null {
			if (this.activeTask?.Status && !isTaskFail(this.activeTask.Status)) {
				return null;
			}
			const statusTransition =
				this.activeTask?.StatusTransitions &&
				this.activeTask.StatusTransitions.filter((st) => st.Status == this.activeTask?.Status)[0];

			// Kea-cloud errors are formatted as `ErrXXX: Error message.`, where `ErrXXX` is an enum-like identifier.
			// This code checks if the message contains a colon, splits it, and returns the error message without the prefix.
			if (statusTransition && statusTransition.Message?.includes(":")) {
				const messageParts = statusTransition.Message.split(":");
				return messageParts.slice(1).join(":").trim() || null;
			}
			return statusTransition?.Message ?? null;
		},
		buildStatusMessageOnError: function (): string | null {
			if (this.activeBuild?.Status && !isBuildFail(this.activeBuild.Status)) {
				return null;
			}
			const statusTransition =
				this.activeBuild?.StatusTransitions &&
				this.activeBuild.StatusTransitions.filter((st) => st.Status == this.activeBuild?.Status)[0];

			// Kea-cloud errors are formatted as `ErrXXX: Error message.`, where `ErrXXX` is an enum-like identifier.
			// This code checks if the message contains a colon, splits it, and returns the error message without the prefix.
			if (statusTransition && statusTransition.Message?.includes(":")) {
				const messageParts = statusTransition.Message.split(":");
				return messageParts.slice(1).join(":").trim() || null;
			}
			return statusTransition?.Message ?? null;
		},
		buildStatusColor(): string {
			return util.buildStatusColor(this.buildStatus);
		},
		taskStatusColor(): string {
			return util.taskStatusColor(this.taskStatus);
		},
		activeBuildStatus(): boolean {
			return this.buildStatus ? isBuildActive(this.buildStatus) : false;
		},
		activeTaskStatus(): boolean {
			return this.taskStatus ? isTaskActive(this.taskStatus) : false;
		},
		buildStatusText(): string {
			// function to trim long status names in mobile
			if (!this.$vuetify.breakpoint.mobile) {
				return this.buildStatus ?? "";
			}

			switch (this.buildStatus) {
				case "Tier Limit Reached":
					return "Tier Limited";
				default:
					return this.buildStatus ?? "";
			}
		},
		taskStatusText(): string {
			// function to trim long status names in mobile
			if (!this.$vuetify.breakpoint.mobile) {
				return this.taskStatus ?? "";
			}

			switch (this.taskStatus) {
				case "Task Not Found":
					return "Not Found";
				case "Tier Limit Reached":
					return "Tier Limited";
				case "Task Rejected":
					return "Rejected";
				case "Upgrade Your Account":
					return "Upgrade Account";
				default:
					return this.taskStatus ?? "";
			}
		},
		buildIsActive(): boolean | undefined {
			return this.buildStatus && isBuildActive(this.buildStatus);
		},
		taskIsActive(): boolean | undefined {
			return this.taskStatus && isTaskActive(this.taskStatus);
		},
		actionButtonLoading(): boolean | undefined {
			return this.buildIsActive || this.taskIsActive;
		},
	},
	methods: {
		postAsJob() {
			// @ts-ignore
			this.$posthog?.capture("run_source_task_button_clicked");
			this.$emit("create-task-request");
		},
		sendCancelActiveTask() {
			this.$emit("cancel-active-task-request");
		},
		sendCancelActiveBuild() {
			this.$emit("cancel-active-build-request");
		},
	},
});
