import { map, upperFirst } from "lodash";
import { TaskOutput, TaskStatistics, TaskStatus, TaskStatusE } from "@/types/api/tasks";
import { isTaskActive, isTaskSuccess, isTaskFail } from "@/types/api/tasks";
import { Core } from "@/types/api/cores";
import {
	ImageV1Content,
	ImageV2Content,
	ImageV3Content,
	ImageV4Content,
	ResultsPanelTab,
	TextContent,
	TransformedTabContent,
} from "@/types/general";
import { isLocalMountConfig, LocalMountConfig } from "@/types/api/dataSources";
import { defaultTabs } from "@/assets/defaultTabs";
import { forgetAnalyticsUser } from "@/plugins/identify";
import moment from "moment";
import { monitoringCaptureError } from "@/plugins/monitoring";
import { CORE_NAMES, CORE_NAMES_TO_DESCRIPTION } from "@/types/utilities";
import { BuildStatus, BuildStatusE, isBuildActive, isBuildFail, isBuildSuccess } from "@/types/api/builds";

export function toStringWithMagnitudeSuffix(
	value: number | undefined | null,
	suffixArray: string[],
	separator: string = "\u0020"
) {
	if (value === undefined || value === null) {
		return "--";
	}
	let suffixArrayIndex = 0;
	while (value >= 1000 && suffixArrayIndex < suffixArray.length - 1) {
		value = value / 1000;
		suffixArrayIndex++;
	}
	const suffixToShow = suffixArray[Math.min(suffixArrayIndex, suffixArray.length - 1)];
	return `${value.toFixed(0)}${suffixToShow ? separator : ""}${suffixToShow}`;
}

export function toStringWithMagnitude(value: number | undefined | null, separator = "\u0020") {
	const suffixArray = ["", "thousand", "million", "billion"];
	return toStringWithMagnitudeSuffix(value, suffixArray, separator);
}
export function toStringWithByteMagnitude(value: number | undefined | null, separator = "\u0020") {
	const suffixArray = ["", "KB", "MB", "GB"];
	return toStringWithMagnitudeSuffix(value, suffixArray, separator);
}
export function toStringWithSIMagnitude(value: number | undefined | null, separator = "\u0020") {
	const suffixArray = ["", "K", "M", "G"];
	return toStringWithMagnitudeSuffix(value, suffixArray, separator);
}

export function usDurationToString(durationInUS: number | undefined | null) {
	if (durationInUS === undefined || durationInUS === null) {
		return "---";
	}

	const milliseconds = Math.trunc(durationInUS / 1000);
	const microSeconds = durationInUS % 1000;

	let formattedString = msDurationToString(milliseconds);

	if (microSeconds > 0) {
		formattedString += `${microSeconds.toFixed(0)} \u03BCs`;
	}

	return formattedString;
}

export function msDurationToString(durationInMS: number | undefined | null) {
	if (durationInMS === undefined || durationInMS === null) {
		return "---";
	}

	const durationObject = moment.duration(durationInMS);

	if (!durationObject.isValid()) {
		return "---";
	}

	let formattedString = "";

	if (durationObject.asDays() > 1) {
		formattedString += `${Math.ceil(durationObject.asDays() - 1)} day${durationObject.asDays() > 2 ? "s" : ""} `;
	}

	if (durationObject.hours() > 0) {
		formattedString += `${Math.ceil(durationObject.hours())} h `;
	}

	if (durationObject.minutes() > 0) {
		formattedString += `${Math.ceil(durationObject.minutes())} m `;
	} else if (formattedString.length > 0) {
		formattedString += `0 m `;
	}

	if (durationObject.seconds() > 0) {
		formattedString += `${Math.ceil(durationObject.seconds())} s `;
	} else if (formattedString.length > 0) {
		formattedString += `0 s `;
	}

	if (durationObject.milliseconds() > 0) {
		formattedString += `${Math.ceil(durationObject.milliseconds())} ms `;
	} else if (formattedString.length > 0) {
		formattedString += `0 ms `;
	}

	return formattedString;
}

export function numberLessThanTenToWords(value: number | undefined | null) {
	if (value === null) {
		return "";
	}
	// undefined is unlimited resource
	if (value === undefined) {
		return "unlimited";
	}
	switch (value) {
		case 9:
			return "nine";
		case 8:
			return "eight";
		case 7:
			return "seven";
		case 6:
			return "six";
		case 5:
			return "five";
		case 4:
			return "four";
		case 3:
			return "three";
		case 2:
			return "two";
		case 1:
			return "one";
		case 0:
			return "zero";
		default:
			return value.toString();
	}
}

export function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function buildStatusColor(status: BuildStatus | undefined) {
	if (status === undefined) {
		return "error";
	} else if (status == BuildStatusE.TierLimited) {
		return "info";
	} else if (isBuildActive(status)) {
		return "warning";
	} else if (isBuildSuccess(status)) {
		return "success";
	} else if (isBuildFail(status)) {
		return "error";
	} else {
		return "error";
	}
}
export function taskStatusColor(status: TaskStatus | undefined) {
	if (status === undefined) {
		return "error";
	} else if (status == TaskStatusE.TierLimited || status == TaskStatusE.UpgradeAccount) {
		return "info";
	} else if (isTaskActive(status)) {
		return "warning";
	} else if (isTaskSuccess(status)) {
		return "success";
	} else if (isTaskFail(status)) {
		return "error";
	} else {
		return "error";
	}
}

export function taskStatusIcon(status: TaskStatus | undefined) {
	if (status === undefined) {
		return "";
	} else if (status == TaskStatusE.TierLimited || status == TaskStatusE.UpgradeAccount) {
		return "info";
	} else if (status == TaskStatusE.Accepted) {
		return "mdi-tray-plus";
	} else if (status == TaskStatusE.Initialising) {
		return "mdi-database-sync";
	} else if (status == TaskStatusE.InProgress) {
		return "mdi-memory";
	} else if (status == TaskStatusE.Completed) {
		return "mdi-check";
	} else if (status == TaskStatusE.Stopped || status == TaskStatusE.Cancelled) {
		return "mdi-alert";
	} else if (isTaskSuccess(status)) {
		return "";
	} else if (isTaskFail(status)) {
		return "";
	} else {
		return "";
	}
}

export function projectSourceIcon(projectType: string) {
	switch (projectType) {
		case "SourceCode":
			return "mdi-script-text-play";
		case "Repository":
			return "mdi-book";
		// case TaskTypeE.Pipeline:
		// 	return "mdi-transit-connection-variant";
		// case TaskTypeE.Module:
		// 	return "mdi-package";
		// case "Schedule":
		// 	return "mdi-calendar-clock";
		// case TaskTypeE.Input:
		// 	return "mdi-import";
		default:
			return "mdi-progress-question";
	}
}

export function filterEveryNValue(inputArray: any[], n: number) {
	return inputArray.map((v, i, arr) => {
		if (i == 0 || i == arr.length - 1 || i % n == 0) {
			return v;
		} else {
			return "";
		}
	});
}

function findTreeMidpoints(inputArray: any[], l: number): string[] {
	const midpoint = Math.floor(inputArray.length / 2);
	if (l == 0 || inputArray.length < 2) {
		return [inputArray[midpoint]];
	} else {
		l -= 1;
		return [
			...findTreeMidpoints(inputArray.slice(0, midpoint), l),
			inputArray[midpoint],
			...findTreeMidpoints(inputArray.slice(midpoint), l),
		];
	}
}

export function symmetricFilter(inputArray: any[], depth: number): any[] {
	// @ts-ignore FIXME:
	const output = ["" * inputArray.length];
	const filteredValues = [inputArray[0], ...findTreeMidpoints(inputArray, depth), inputArray[inputArray.length - 1]];

	// construct label array
	filteredValues.map((displayValue) => {
		output[
			inputArray.findIndex((originalValue) => {
				return originalValue == displayValue;
			})
		] = displayValue;
	});

	return output;
}

export function convertLocalCoreObjectToRemoteFormat(coreConfig: Core) {
	//* Deprecated
	type OldCore = {
		Id: string;
		Name: string;
		Class: string;
		MemorySize: number;
		Microarchitecture: string;
		CorrelationTracking: string;
		TotalUsage: number;
		AvailableUsage: number;
		Immutable: boolean;
		Disabled: boolean;
		ReferencePrecision: number;
		AutocorrelationTrackingEnabled: boolean;
		Editable: Boolean;
	};

	// PATCH: change keys to PascalCase before saving
	// eslint-disable-next-line prefer-const
	let newCoreConfig: Partial<Core & OldCore> = {};

	for (const key in coreConfig) {
		if (Object.prototype.hasOwnProperty.call(coreConfig, key)) {
			// @ts-ignore FIXME:
			newCoreConfig[upperFirst(key) as keyof typeof newCoreConfig] = coreConfig[key as keyof typeof coreConfig];
		}
	}
	// PATCH: change user core id key from "Id" to "CoreID"
	newCoreConfig.CoreID = newCoreConfig.Id ?? newCoreConfig.CoreID;

	// PATCH: set core precision key if reference core
	if (
		newCoreConfig.Microarchitecture === "Reference" &&
		newCoreConfig.ReferencePrecision &&
		newCoreConfig.Precision !== newCoreConfig.ReferencePrecision
	) {
		newCoreConfig.Precision = newCoreConfig.ReferencePrecision;
	}

	// PATCH: set core correlation type to string
	if (!newCoreConfig.CorrelationTracking) {
		if (newCoreConfig.Microarchitecture === "Reference" || newCoreConfig.Microarchitecture === "Bypass") {
			newCoreConfig.CorrelationTracking = "disable";
		} else {
			newCoreConfig.CorrelationTracking =
				newCoreConfig.AutocorrelationTrackingEnabled !== undefined ? "autocorrelation" : "disable";
		}
	}

	// PATCH: remove unused keys
	delete newCoreConfig.Id;
	delete newCoreConfig.AutocorrelationTrackingEnabled;
	delete newCoreConfig.Editable;
	delete newCoreConfig.ReferencePrecision;
	delete newCoreConfig.TotalUsage;
	delete newCoreConfig.AvailableUsage;

	return newCoreConfig;
}

// @ts-ignore FIXME:
export function convertLocalRepositoryObjectToRemoteFormat(repoConfig) {
	// TODO::
}

// @ts-ignore FIXME:
export function convertLocalPipelineObjectToRemoteFormat(pipelineConfig) {
	// TODO::
}

// @ts-ignore FIXME:
export function changeKeysToPascalCase(obj) {
	const newObj: { [key: string]: any } = {};
	for (const key in obj) {
		newObj[upperFirst(key)] = obj[key];
	}
	return newObj;
}

export function repoFullNameFromUrl(url: string) {
	const urlParts = url.split("/");
	return urlParts[urlParts.length - 2] + "/" + urlParts[urlParts.length - 1];
}

/*
	- Insures 32 alphanumerical chars are after val_
	- Insures 40 hex characters are after Ux
	- Insures that we correctly parse positive and negative floats, ints particle values
	- Matches scientific notations.
*/
export function transformTabContent(content: string): TransformedTabContent[] {
	const startTime = performance.now();

	const results: TransformedTabContent[] = [];
	if (!content) {
		console.log(`Performance: ${performance.now() - startTime}ms`);
		return results;
	}

	let lastIndex = 0;
	const floatRegex = /([-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?)\b$/;
	const patterns = [
		{ regex: /<ValueID>(.*?)<\/ValueID>/g, identifier: "ValueID" },
		{ regex: /(Ux[0-9a-fA-F]{40,})\b/g, identifier: "UxString" },
	];

	function addTextIfNeeded(text: string, isLastSegment = false) {
		if (text || isLastSegment) {
			results.push({ type: "text", body: text });
		}
	}

	function addResult(prefix: string, value: string, identifier: string, identifierValue: string) {
		addTextIfNeeded(prefix);
		if (value) {
			results.push({
				type: "image",
				version: "v4",
				body: {
					Value: value,
					[identifier]: identifierValue,
					PlotLoading: true,
					ImageURL: "",
				},
			});
		}
	}

	for (const { regex, identifier } of patterns) {
		regex.lastIndex = 0; // Reset regex index
		let match;
		while ((match = regex.exec(content)) !== null) {
			const segment = content.substring(lastIndex, match.index);
			const floatMatch = segment.match(floatRegex);
			const value = floatMatch ? floatMatch[0] : "";
			const prefix = floatMatch ? segment.substring(0, floatMatch.index) : segment;
			addResult(prefix, value, identifier, match[1]);
			lastIndex = regex.lastIndex;
		}
	}

	if (lastIndex < content.length) {
		addTextIfNeeded(content.substring(lastIndex), true);
	}

	console.log(`Performance: ${performance.now() - startTime}ms`);
	return results;
}

export function parseDDP0x42String(xmlString: string): ImageV1Content {
	// Parse plot v 1 (DistributionalDataPrint0x42)
	// - text followed by presigned URL within the XML tags

	const extractedImageURL = xmlString
		.replace("<DistributionalDataPrint0x42>", "")
		.replace("</DistributionalDataPrint0x42>", "")
		.trim();

	return {
		type: "image",
		version: "v1",
		body: { ImageURL: extractedImageURL },
	};
}
export function parseDDP0x45String(xmlString: string): ImageV2Content {
	// Parse plot v2 (DistributionalDataPrint0x45)
	// - json inside XML tags with float value and a presigned URL for the value
	const newVal = xmlString
		.replace("<DistributionalDataPrint0x45>", "")
		.replace("</DistributionalDataPrint0x45>", "")
		.trim();
	/*
	 *	According to the format specification, newVal should be
	 *	valid JSON.
	 */

	try {
		const parsedJson = JSON.parse(newVal, (k, v) => {
			switch (k) {
				case "ImageURL":
					/*
					 *	ImageURL is URI-encoded (e.g., '&' appears as '\u0026').
					 */
					return decodeURI(v);

				default:
					return v;
			}
		});
		return {
			type: "image",
			version: "v2",
			body: parsedJson,
		};
	} catch (error) {
		monitoringCaptureError(error, "Parse Ux45 string");
		if (error instanceof SyntaxError) {
			/*
			 *	Error was because of invalid JSON input syntax.
			 */
			return {
				type: "image",
				version: "v2",
				body: {
					Value: "???????",
					ImageURL: undefined,
				},
			};
		} else if (error instanceof URIError) {
			/*
			 *	Error was because of invalid URI input syntax for key "ImageURL".
			 */
			return {
				type: "image",
				version: "v2",
				body: {
					Value: "???????",
					ImageURL: undefined,
				},
			};
		}
		/*
		 *	Error is of unaccounted-for cause.
		 */
		return {
			type: "image",
			version: "v2",
			body: {
				Value: "???????",
				ImageURL: undefined,
			},
		};
	}
}

export function parseDDP0x46String(xmlString: string): ImageV3Content {
	// Parse plot v3 (DistributionalDataPrint0x46)
	// - json inside XML tags with float value and a value ID
	const newVal = xmlString
		.replace("<DistributionalDataPrint0x46>", "")
		.replace("</DistributionalDataPrint0x46>", "")
		.trim();
	/*
	 *	According to the format specification, newVal should be
	 *	valid JSON.
	 */

	try {
		const parsedJson = JSON.parse(newVal);
		parsedJson.ImageURL = "";
		parsedJson.PlotLoading = true;
		return {
			type: "image",
			version: "v3",
			body: parsedJson,
		};
	} catch (error) {
		monitoringCaptureError(error, "Parse Ux46 string");
		/*
		 *	Error is of unaccounted-for cause.
		 */
		return {
			type: "image",
			version: "v3",
			body: {
				Value: "???????",
				ValueID: undefined,
			},
		};
	}
}

export function clearLocalState() {
	// Delete local storage
	localStorage.clear();
	// reset analytics config
	// @ts-ignore: Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
	globalThis.analytics?.reset();
	forgetAnalyticsUser();
}

export function isAbsoluteURL(url: string) {
	/*
	 *	From https://stackoverflow.com/a/19709846/7151170
	 */
	const r = new RegExp("^(?:[a-z+]+:)?//", "i");
	return r.test(url);
}

export function shortenID(id: string, shortLength = 7) {
	const prefix = "tsk_";
	return id.slice(0, shortLength + prefix.length);
}

export function sortArryOfObjectByKey<T>(arrayOfObjects: T[], key: keyof T) {
	// if (typeof key of  T[key] === "number")
	return arrayOfObjects.sort((a, b) => {
		// @ts-ignore FIXME: check for numerics
		return a[key] - b[key];
	});
}

export function parseTaskStatsObjectToStatsTabString(taskStats: undefined | TaskStatistics): string {
	let statsTabText = "";

	if (taskStats?.ProcessorTime) {
		statsTabText += `C0 execution time:  ${usDurationToString(taskStats.ProcessorTime * 1000_000)}\n`;
	}

	if (taskStats?.DynamicInstructions) {
		statsTabText += `Dynamic instruction credits consumed: ${taskStats.DynamicInstructions.toLocaleString()}\n`;
	}

	return statsTabText;
}

export function base64Encode(str) {
	return Buffer.from(str).toString("base64");
}
export function base64Decode(str) {
	// From https://stackoverflow.com/questions/8936984/uint8array-to-string-in-javascript
	return new TextDecoder().decode(Buffer.from(str, "base64"));
}

// Generic wait for function based on https://stackoverflow.com/questions/7193238/wait-until-a-condition-is-true
export function waitFor(conditionFunction) {
	const poll = (resolve) => {
		if (conditionFunction()) resolve();
		else setTimeout((_) => poll(resolve), 400);
	};

	return new Promise(poll);
}

export function parseMountConfigString(mountConfigString?: string): undefined | null | LocalMountConfig {
	if (mountConfigString === null) {
		return null;
	}
	if (!mountConfigString) {
		return undefined;
	}

	if (mountConfigString === "undefined") {
		// the string "undefined"  => data sources not set
		return undefined;
	} else if (mountConfigString === "null") {
		// the string "null"  => set to no data sources
		return null;
	} else {
		// some other string => possibly a mount config
		try {
			// try to parse it
			const parsedEditorDataSourcesFromDB = JSON.parse(mountConfigString);
			if (isLocalMountConfig(parsedEditorDataSourcesFromDB)) {
				// if the parsed object is a valid mount config return it
				return parsedEditorDataSourcesFromDB;
			} else {
				throw new Error("Error parsing the string mount config");
			}
		} catch (error) {
			monitoringCaptureError(error, "Parse mount config string");
			// if parsing error... return undefined
			return undefined;
		}
	}
}

export function checkCoreRequirements(coreToCheck, minCore) {
	const requirementMatch = {};
	for (const key in minCore) {
		// check if requirement satisfied for numerical constraints
		if (typeof minCore[key] == "number") {
			requirementMatch[key] = coreToCheck[key] >= minCore[key];
		} else {
			// check if requirement satisfied for non numerical constraints
			if (["Class", "Microarchitecture", "CorrelationTracking"].includes(key)) {
				requirementMatch[key] = coreToCheck[key] === minCore[key];
			}
		}
	}
	return requirementMatch;
}

export function secondsSinceEpochToDateString(timestamp: number) {
	//moment requires timestamp in milliseconds
	return moment(timestamp * 1000).calendar(null, {
		sameDay: "[Today]",
		nextDay: "[Tomorrow]",
		nextWeek: "dddd",
		lastDay: "[Yesterday]",
		lastWeek: "[Last] dddd",
		sameElse: "DD/MM/YYYY",
	});
}

export function millisecondsSinceEpochToDateString(timestamp: number) {
	//moment requires timestamp in milliseconds
	return moment(timestamp).calendar(null, {
		sameDay: "[Today]",
		nextDay: "[Tomorrow]",
		nextWeek: "dddd",
		lastDay: "[Yesterday]",
		lastWeek: "[Last] dddd",
		sameElse: "DD/MM/YYYY",
	});
}
function isMilliseconds(timestamp) {
	return timestamp > 1e11; // If greater than 100 billion, assume milliseconds
}

export function convertToMilliseconds(timestamp) {
	return isMilliseconds(timestamp) ? timestamp : timestamp * 1000;
}

export function formatDuration(startTimestamp, endTimestamp, secondsOnly = false) {
	const AsTimeStamp_19900101 = 631152000;

	if (!startTimestamp || !endTimestamp) {
		return "---";
	}

	const startMs = convertToMilliseconds(startTimestamp);
	const endMs = convertToMilliseconds(endTimestamp);

	if (startTimestamp <= AsTimeStamp_19900101 || endTimestamp <= AsTimeStamp_19900101) {
		return "---";
	}

	const finishTime = moment(endMs);
	const startTime = moment(startMs);

	if (finishTime.isValid() && startTime.isValid()) {
		const duration = finishTime.diff(startTime);
		if (duration < 1000) {
			return secondsOnly ? "< 1s" : `${duration}ms`;
		} else if (duration < 60000) {
			return `${Math.floor(duration / 1000)}s` + (secondsOnly ? "" : ` ${duration % 1000}ms`);
		} else {
			return `${Math.floor(duration / 60000)}m ${Math.floor((duration % 60000) / 1000)}s`;
		}
	} else {
		return "---";
	}
}

export const ContactSignaloidSupportMessage =
	"If this error persists, please contact support at developer-support@signaloid.com.";

export function coreDescriptionString(coreName: string, firstSentence: boolean = false): string {
	const coreNameLowerCase = coreName.toLowerCase();
	const description = CORE_NAMES_TO_DESCRIPTION[coreNameLowerCase];
	if (!description) {
		console.warn("Unkown core name", coreNameLowerCase);
		return "";
	}
	if (firstSentence) {
		return description.split(".")[0] + ".";
	} else {
		return description;
	}
}
