
import { defineComponent } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import type { BarSeriesOption } from "echarts/charts";
import { LineChart, BarChart } from "echarts/charts";
import {
	TitleComponent,
	TooltipComponent,
	DataZoomComponent,
	ToolboxComponent,
	LegendComponent,
	GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { colors } from "vuetify/lib";
import { useTasksStore } from "@/stores/tasks";
import moment from "moment";
import * as util from "@/js/utilities";

use([
	GridComponent,
	CanvasRenderer,
	LineChart,
	BarChart,
	TitleComponent,
	TooltipComponent,
	ToolboxComponent,
	DataZoomComponent,
	LegendComponent,
]);
type PlotData = { dateTimestamp: number; date: string; taskTriggers: number; megaInstructions?: number };

type ComponentData = {
	plotData: PlotData[];
	taskHistoryDays: number;
	targetYAxisPoints: number;
	possibleYAxisIntervals: number[];
};
export default defineComponent({
	name: "UsageLineChart",
	components: {
		VChart,
	},
	provide: {
		[THEME_KEY]: "light",
	},
	setup() {
		const taskStore = useTasksStore();
		return { taskStore };
	},
	data: (): ComponentData => ({
		plotData: [],
		taskHistoryDays: 30,
		targetYAxisPoints: 6,
		possibleYAxisIntervals: [1, 5, 10, 20, 50, 100, 150, 200, 300, 500, 1000],
	}),
	computed: {
		option(): any {
			return {
				animation: true,
				title: {
					text: "",
					left: "center",
				},
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow",
					},
				},
				xAxis: [
					{
						name: "Date (UTC)",
						nameLocation: "middle",
						nameGap: 25,

						type: "category",
						data: this.plotData.map(({ date }) => date),
						axisPointer: {
							type: "shadow",
						},
					},
				],
				yAxis: [
					{
						name: "# Compute Tasks",
						type: "value",
						interval: this.determineYAxisInterval,
						nameLocation: "middle",
						nameGap: 35,
					},
				],
				series: [
					{
						name: "# Compute Tasks",
						type: "bar",
						data: this.plotData.map(({ taskTriggers }) => taskTriggers),
						yAxisIndex: 0,
						itemStyle: {
							color: "#91cc75",
						},
						showSymbol: false,
						emphasis: { disabled: true },
						select: { disabled: true },
					},
				],
				grid: [
					{
						left: 60,
						right: 30,
						bottom: 45,
						top: 20,
					},
				],
			};
		},
		determineYAxisInterval(): number {
			let largestXAxis = 0;
			this.plotData.forEach((plotPoint) => {
				if (plotPoint.taskTriggers > largestXAxis) {
					largestXAxis = plotPoint.taskTriggers;
				}
			});

			const determinedInterval = Math.ceil(largestXAxis / this.targetYAxisPoints);
			let idealIntervalIndex = 0;

			for (let i = 0; i < this.possibleYAxisIntervals.length - 2; i++) {
				const currentPossibleInterval = this.possibleYAxisIntervals[i];
				const nextPossibleInterval = this.possibleYAxisIntervals[i + 1];
				if (currentPossibleInterval <= determinedInterval && determinedInterval <= nextPossibleInterval) {
					idealIntervalIndex = i + 1;
					break;
				}
			}
			return this.possibleYAxisIntervals[idealIntervalIndex];
		},
	},

	methods: {
		formattedTaskHistory(): void {
			if (!this.taskStore.taskMetadataHistory) {
				return;
			}

			const historyDurationDays = this.taskHistoryDays;
			const historyEarliestDate = moment().subtract(historyDurationDays, "days");

			const plotData: PlotData[] = [];

			const plotEndDate = moment();
			plotEndDate.seconds(0);
			plotEndDate.minutes(0);
			plotEndDate.hours(0);

			var currentDate = plotEndDate;
			while (currentDate >= historyEarliestDate) {
				const currentDateObject = moment(currentDate);
				plotData.push({
					date: currentDateObject.format("YYYY/MM/DD"),
					dateTimestamp: parseInt(currentDateObject.format("X")),
					taskTriggers: 0,
				});

				currentDate = currentDateObject.subtract(1, "days");
			}

			// assume task history sorted latest to earliest
			// go through the task history...
			let currentDateWindowIndex = 0;
			this.taskStore.taskMetadataHistory.map((task) => {
				// If the task is before the current boundary find the boundary that the task is after

				while (
					// FIXME: plotData[x] potentially undefined
					plotData[currentDateWindowIndex] !== undefined &&
					task.CreatedAt < plotData[currentDateWindowIndex].dateTimestamp &&
					currentDateWindowIndex < plotData.length
				) {
					currentDateWindowIndex += 1;
				}

				// at this point task.CreatedAt >= plotData[currentDateWindowIndex].dateTimestamp
				// If the task is after the current boundary add to the current executions count
				if (
					plotData[currentDateWindowIndex] !== undefined &&
					task.CreatedAt >= plotData[currentDateWindowIndex].dateTimestamp
				) {
					plotData[currentDateWindowIndex].taskTriggers += 1;
				}
			});

			this.plotData = plotData.reverse();
		},
	},
	async created() {
		// fetch partial task history
		await this.taskStore.getTasksMetadataHistoryWithinDays(this.taskHistoryDays);
	},
	beforeMount() {
		util.waitFor(() => this.taskStore.taskMetadataHistory !== undefined).then(() => {
			this.formattedTaskHistory();
		});
	},
});
