var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "900px" },
      on: {
        "click:outside": _vm.closeCard,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeCard.apply(null, arguments)
        },
      },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "pa-1",
          attrs: {
            rounded: "lg",
            height: _vm.$vuetify.breakpoint.mobile ? "90vh" : "75vh",
          },
        },
        [_c("Panel", { attrs: { taskID: _vm.taskID, buildID: _vm.buildID } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }